import { PureComponent } from "react";
import cn from "classnames";
import axios from "../../utils/axios";

export default class MainSearchResults extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      items: []
    };
  }

  componentWillMount() {
    const {
      props: { resultItems }
    } = this;
    this.setState({ items: resultItems });
  }

  onSubscribeBtnClick = e => {
    e.stopPropagation();
  };

  hasNextPage(count) {
    const {
      state: { items }
    } = this;
    return count - items.length > 0;
  }

  handleShowMore = (count, per, term) => {
    const {
      state: { page, items }
    } = this;
    const category = items[0].category;
    const nextPage = page + 1;
    axios
      .get(
        `/search/autocomplete?q=${term}&category=${category}&page=${nextPage}&per_page=${per}`
      )
      .then(({ data }) => {
        if (data && data.length > 0) {
          const newItems = [...items, ...data];
          this.setState({ items: newItems, page: nextPage });
        }
      });
  };

  subscribeBtnText = isSubscribed => {
    if (isSubscribed) {
      return '<span>Подписан<svg class="feed-subscribe-btn-icon" height=8px version=1.1 viewBox="0 0 9 7"width=10px xmlns=http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink><title>Shape</title><desc>Created with Sketch.</desc><defs></defs><g id=Page-1 fill-rule=evenodd fill=currentColor stroke-width=1><g id=Desktop-HD-Copy-172 fill-rule=nonzero transform="translate(-240.000000, -438.000000)"><g id=Group-3-Copy transform="translate(232.000000, 429.000000)"><path d="M10.9790663,13.7384761 L8.82226258,11.9411297 L8.09445227,12.8153996 L10.6506641,14.9455761 L11.0500722,15.2784091 L11.4184223,14.9100732 C12.9322961,13.3929473 14.7311166,11.6006579 16.2468224,10.0816702 L15.4391305,9.27840909 C14.0407548,10.6798238 12.4159982,12.2998117 10.9790663,13.7384761 Z"id=Shape></path></g></g></g></svg>';
    }
    return '<span>Подписаться<svg class="feed-subscribe-btn-icon" height="11px" version="1.1" viewBox="0 0 12 12" width="11px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Group 7</title><desc>Created with Sketch.</desc><defs></defs><g id="Page-1" stroke="none" fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="1"><g id="Desktop-HD-Copy-171" transform="translate(-195.000000, -314.000000)" stroke="currentColor"><g id="Group-8" transform="translate(182.000000, 301.000000)"><g id="Group-7" transform="translate(14.000000, 14.000000)"><path d="M5,0.384615385 L5,9.61538462" id="Line"></path><path d="M9.61538462,5 L0.384615385,5" id="Line-Copy"></path></g></g></g></g></svg></span';
  };

  render() {
    const {
      state: { items },
      props: {
        compact,
        title,
        count,
        searchTerm,
        hasSubscribtion,
        onResultClick,
        startPage
      }
    } = this;
    const subscribeRole = "SubscribeFeed";
    return (
      <div
        className={cn("header-search_results", {
          "startpage-search-results": startPage
        })}
      >
        <span className="header-search_results-title">
          <strong className="header-search_results-title-value">{title}</strong>
          <span className="header-search_results-title-count">{count}</span>
        </span>
        <div className="header-search_results-list">
          {items.map((item, index) => (
            <a
              // eslint-disable-next-line
              key={index}
              className={cn("header-search_results-item", {
                "header-search_results-item-compact": compact
              })}
              href={item.url}
              onClick={onResultClick}
            >
              <div className="preview-box-post-imgwrapper">
                {item.image_url ? (
                  <img
                    className="preview-box-post-img preview-box-post-img_75"
                    src={item.image_url}
                    alt={item.value}
                  />
                ) : (
                  <img
                    className="preview-box-post-img"
                    src="https://cdn.relap.io/4WneOg/7g/1/7gsxIg.jpg"
                    alt="img"
                  />
                )}
              </div>

              {item.date && (
                <span
                  className="header-search_results-text header-search_results-text-top"
                  dangerouslySetInnerHTML={{ __html: item.date }}
                />
              )}

              <span>
                <span className="header-search_results-value">
                  {item.value}
                </span>

                {/* <Highlighter className="header-search_results-value" searchWords={[searchTerm]} textToHighlight={(item.value)} /> */}

                {hasSubscribtion && item.is_subscribed && (
                  <span
                    className={cn("btn-small feed-subscribe-btn", {
                      subscribed: item.is_subscribed
                    })}
                    data-feedable-id={item.id}
                    data-feedable-type={item.klass}
                    role={subscribeRole}
                    onClick={e => this.onSubscribeBtnClick(e)}
                    dangerouslySetInnerHTML={{
                      __html: this.subscribeBtnText(item.is_subscribed)
                    }}
                  />
                )}
                {hasSubscribtion && !item.is_subscribed && (
                  <span
                    className={cn("btn-small feed-subscribe-btn", {
                      subscribed: item.is_subscribed
                    })}
                    data-feedable-id={item.id}
                    data-feedable-type={item.klass}
                    role={subscribeRole}
                    onClick={e => this.onSubscribeBtnClick(e)}
                    dangerouslySetInnerHTML={{
                      __html: this.subscribeBtnText(item.is_subscribed)
                    }}
                  />
                )}
              </span>

              {item.organization && (
                <span dangerouslySetInnerHTML={{ __html: item.organization }} />
              )}

              {(item.place_or_online || item.is_promo) && (
                <span className="header-search_results-text header-search_results-text-bottom">
                  {item.place_or_online && (
                    <span
                      dangerouslySetInnerHTML={{ __html: item.place_or_online }}
                    />
                  )}

                  {item.is_promo && (
                    <span
                      className="startpage-item-label"
                      style={{
                        bottom: item.place_or_online ? "-3px" : "-17px"
                      }}
                    >
                      промо
                    </span>
                  )}
                </span>
              )}
            </a>
          ))}
        </div>
        {this.hasNextPage(count) && (
          <a
            className="btn-small"
            onClick={() =>
              this.handleShowMore(count, compact ? 3 : 4, searchTerm)
            }
          >
            Показать еще
          </a>
        )}
      </div>
    );
  }
}
