import { useState, useEffect, useRef } from "react";
import { useDebounce } from "use-debounce";
import PropTypes from "prop-types";
import cn from "classnames";
import axios from "../../utils/axios";
import MainSearchResults from "./SearchResults";
import analiticsTrack from "../../utils/ga";
import HeaderMenu from "./HeaderMenu";
import MobileMenu from "./MobileMenu";
import UserBar from "./UserBar";
import ToggleMenu from "./ToggleMenu";

const initSearchResults = {
  objects: [],
  counts: {
    media_posts: 0,
    seminars: 0,
    seminar_cycles: 0,
    course_courses: 0,
    grants_grants: 0,
    video_videos: 0,
    media_projects: 0,
    organizations: 0,
    presenters: 0,
    places: 0,
    tags: 0
  }
};

export default function MainHeader({
  userBarLinks,
  userAvatar,
  isUserLoggedIn,
  feedsItems,
  headerMenuLinks,
  toggleMenuLinks,
  bannerUrl,
  bannerImage
}) {
  const hideTags = false;
  const [searchTerm, setSearchTerm] = useState("");
  const [cookieSearchTerm, setCookieSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideResults, setHideResults] = useState(true);
  const [searchResults, setSearchResults] = useState(initSearchResults);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const searchWrapperEl = useRef(null);
  const searchInputEl = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userDropdownisOpen, setUserDropdownisOpen] = useState(false);
  const [toggleMenuisOpen, setToggleMenuisOpen] = useState(false);

  const onKeyDown = event => {
    if (event.keyCode === 27 && cookieSearchTerm.length === 0) {
      setSearchTerm("");
      setHideResults(true);
      document
        .querySelectorAll("html, body")
        .forEach(el => el.classList.remove("overflowed"));
      if (document.querySelector(".main_header"))
        document.querySelector(".main_header").classList.remove("search-show");
    }
    if (event.keyCode === 27 && cookieSearchTerm.length > 1) {
      event.preventDefault();
    }
  };

  const handleClickOutside = ({ target }) => {
    const nodes = [
      searchWrapperEl.current,
      document.querySelector("header"),
      document.querySelector(".email_subscription_modal"),
      document.querySelector(".vk__modal"),
      document.querySelector(".facebook__modal")
    ];

    if (nodes.some(node => node && node.contains(target))) {
      return false;
    }

    setSearchResults(initSearchResults);
    return true;
  };

  const getResults = (value, token) => {
    return axios
      .get(`/search/autocomplete?q=${value}&category=all`, {
        cancelToken: token
      })
      .then(({ data }) => {
        setLoading(false);
        return { objects: data.objects, counts: data.counts };
      });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", onKeyDown, false);
    const source = axios.CancelToken.source();

    const term = $.cookie("searchTerm");
    if (term && term.length > 1) {
      setSearchTerm(term);
      setCookieSearchTerm(term);
      setHideResults(false);
      getResults(term, source.token)
        .then(setSearchResults)
        .catch(() => {
          if (axios.isCancel(source)) {
            return;
          }
          setSearchResults(initSearchResults);
        });
      $.removeCookie("searchTerm", { path: "/" });
    }

    if (debouncedSearchTerm) {
      getResults(debouncedSearchTerm, source.token)
        .then(setSearchResults)
        .catch(() => {
          if (axios.isCancel(source)) {
            return;
          }
          setSearchResults(initSearchResults);
        });
    } else {
      setSearchResults(initSearchResults);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", onKeyDown, false);
      source.cancel(
        "Canceled because of component unmounted or search term changed"
      );
    };
  }, [debouncedSearchTerm]);

  const handleSearch = ({ target: { value } }) => {
    const header = document.querySelector("header");
    if (typeof header.scrollIntoView === "function") {
      header.scrollIntoView(true);
    }

    if (value.trim().length < 2) {
      document
        .querySelectorAll("html, body")
        .forEach(el => el.classList.remove("overflowed"));
      setLoading(false);
      setHideResults(true);
      setSearchTerm(value);
    } else if (value.trim().length === searchTerm.trim().length) {
      setHideResults(false);
      setLoading(false);
      setSearchTerm(value);
    } else {
      document
        .querySelectorAll("html, body")
        .forEach(el => el.classList.add("overflowed"));
      setHideResults(false);
      setLoading(true);
      setSearchTerm(value);
      setSearchResults(initSearchResults);
      const term = $.cookie("searchTerm");
      if (!term)
        analiticsTrack(
          "search",
          "input change",
          `header 0 with query ${value}`,
          0,
          false
        );
    }
  };

  const nothingFound = () => {
    return (
      searchResults &&
      searchResults.counts &&
      Object.keys(searchResults.counts)
        .map(k => searchResults.counts[k])
        .reduce((prev, next) => prev + next) === 0
    );
  };

  const onClearClick = () => {
    setSearchTerm("");
    setHideResults(true);
    if (document.querySelector(".main_header"))
      document.querySelector(".main_header").classList.remove("search-show");
    document
      .querySelectorAll("html, body")
      .forEach(el => el.classList.remove("overflowed"));
  };

  const onResultClick = () => {
    analiticsTrack("search", "click", "header 0", 0, false);
    $.cookie("searchTerm", searchTerm, { path: "/" });
  };

  const onSearchTriggerClick = () => {
    if (searchInputEl) {
      const header = document.getElementsByTagName("header")[0];
      if (typeof header.scrollIntoView === "function") {
        header.scrollIntoView(true);
      }
      setTimeout(() => {
        searchInputEl.current.focus();
        document
          .querySelectorAll("html, body")
          .forEach(el => el.classList.add("overflowed"));
      }, 100);
    }
    if (document.querySelector(".main_header"))
      document.querySelector(".main_header").classList.add("search-show");

    setUserDropdownisOpen(false);
    setToggleMenuisOpen(false);
  };

  const toggleMobileMenu = () => {
    const header = document.getElementsByTagName("header")[0];
    if (typeof header.scrollIntoView === "function") {
      header.scrollIntoView(true);
    }
    if (isMobileMenuOpen) {
      document.querySelector("body").classList.remove("show_mobile_nav");
    } else {
      document.querySelector("body").classList.add("show_mobile_nav");
    }
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const onCookieTermClick = term => {
    setSearchTerm(term);
    if (document.querySelector(".main_header"))
      document.querySelector(".main_header").classList.add("search-show");
    document
      .querySelectorAll("html, body")
      .forEach(el => el.classList.add("overflowed"));
  };

  const onSearchTermClearClick = () => {
    setSearchTerm("");
    setHideResults(true);
    setCookieSearchTerm("");
    if (document.querySelector(".main_header"))
      document.querySelector(".main_header").classList.remove("search-show");
    document
      .querySelectorAll("html, body")
      .forEach(el => el.classList.remove("overflowed"));
  };

  const onMenuOverlayClick = () => {
    setUserDropdownisOpen(false);
    setToggleMenuisOpen(false);
  };

  const toggleSidebarMenu = () => {
    setUserDropdownisOpen(false);
    setToggleMenuisOpen(!toggleMenuisOpen);
    if (document.querySelector(".main_header"))
      document.querySelector(".main_header").classList.remove("search-show");
    document
      .querySelectorAll("html, body")
      .forEach(el => el.classList.remove("overflowed"));
  };

  const toggleUserDropdown = () => {
    setUserDropdownisOpen(!userDropdownisOpen);
    setToggleMenuisOpen(false);
  };

  const getResultItemsByType = type => {
    switch (type) {
      case "courses":
        return searchResults.objects.filter(
          item => item.klass === "Course::Course"
        );
      case "seminars":
        return searchResults.objects.filter(
          item => item.klass === "Seminar::Tnp" || item.klass === "Seminar"
        );
      case "grants":
        return searchResults.objects.filter(
          item => item.klass === "Grants::Grant"
        );
      case "posts":
        return searchResults.objects.filter(
          item => item.klass === "Media::Post"
        );
      case "videos":
        return searchResults.objects.filter(
          item => item.klass === "Video::Video"
        );
      case "organizations":
        return searchResults.objects.filter(
          item => item.klass === "Organization"
        );
      case "projects":
        return searchResults.objects.filter(
          item => item.klass === "Media::Project"
        );
      case "presenters":
        return searchResults.objects.filter(item => item.klass === "Presenter");
      case "places":
        return searchResults.objects.filter(item => item.klass === "Place");
      case "seminar_cycles":
        return searchResults.objects.filter(
          item => item.klass === "Seminar::Cycle"
        );
      case "tags":
        return searchResults.objects.filter(item => item.klass === "Tag");
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <ToggleMenu
        toggleMenuLinks={toggleMenuLinks}
        toggleMenuisOpen={toggleMenuisOpen}
        onMenuOverlayClick={onMenuOverlayClick}
        isUserLoggedIn={isUserLoggedIn}
      />

      <div
        className={cn("main_header_content", {
          "show-search-term": cookieSearchTerm.length > 1
        })}
      >
        <div className="header-left">
          <a className="header-logo" href="/">
            T&P
          </a>

          <div className="search-term-wrapper">
            <div
              className="search-term"
              onClick={() => onCookieTermClick(cookieSearchTerm)}
            >
              {cookieSearchTerm}
            </div>
            <div
              className="search-term-clear"
              onClick={() => onSearchTermClearClick()}
            />
          </div>

          <HeaderMenu
            headerMenuLinks={headerMenuLinks}
            onSearchTriggerClick={onSearchTriggerClick}
          />
          {/*
        {bannerImage && bannerUrl && (
          <div className="header-special-link">
            <a href={bannerUrl} target="_blank" rel="noopener noreferrer">
              <img src={bannerImage} alt={bannerUrl} />
            </a>
          </div>
        )} */}
            <MobileMenu
          headerMenuLinks={headerMenuLinks}
          isUserLoggedIn={isUserLoggedIn}
          userBarLinks={userBarLinks}
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
        </div>

        <UserBar
          feedsItems={feedsItems}
          headerMenuLinks={headerMenuLinks}
          userBarLinks={userBarLinks}
          isUserLoggedIn={isUserLoggedIn}
          userAvatar={userAvatar}
          userDropdownisOpen={userDropdownisOpen}
          toggleSidebarMenu={toggleSidebarMenu}
          toggleUserDropdown={toggleUserDropdown}
          onSearchTriggerClick={onSearchTriggerClick}
        />
      </div>

      <div className="header_search">
        <div className="header-search_results-wrapper">
          <div className="main_header_content">
            <input
              ref={searchInputEl}
              value={searchTerm}
              className={cn("search_input header-search_input", {
                "header-search_input-has_value": searchTerm.length > 0
              })}
              placeholder="Чему вы хотите научиться?"
              name="q"
              type="search"
              autoComplete="off"
              onChange={handleSearch}
            />
            <div className="search-input-clear" onClick={onClearClick}>
              +
            </div>
          </div>
          {loading && searchTerm.length > 1 && (
            <div className="header-search_results-container">
              <div className="header-search_results-loading">
                Загружаем&nbsp;
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          )}

          {!hideResults && !loading && nothingFound() && searchTerm.length > 1 && (
            <div className="header-search_results-container">
              <div className="header-search_results-loading">
                Ничего не найдено
              </div>
            </div>
          )}

          {!hideResults && !loading && !nothingFound() && (
            <div className="header-search_results-container">
              {!hideTags &&
                searchResults.objects &&
                getResultItemsByType("tags").length > 0 && (
                  <div className="header-search_results">
                    <span className="header-search_results-title">
                      <strong className="header-search_results-title-value">
                        Теги
                      </strong>
                      <span className="header-search_results-title-count">
                        {getResultItemsByType("tags").length}
                      </span>
                    </span>
                    {getResultItemsByType("tags").map((item, index) => (
                      // eslint-disable-next-line
                      <div key={index} className="search_tag">
                        <a
                          className="search_tag-link"
                          href={item.url}
                          onClick={onResultClick}
                          data-no-turbolink={true}
                        >
                          #{item.name}
                        </a>
                      </div>
                    ))}
                  </div>
                )}

              {searchResults.objects &&
                getResultItemsByType("courses").length > 0 && (
                  <MainSearchResults
                    title="Курсы"
                    count={searchResults.counts.course_courses}
                    resultItems={[
                      ...getResultItemsByType("courses").slice(0, 4)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    onResultClick={onResultClick}
                  />
                )}

              {searchResults.objects &&
                getResultItemsByType("seminars").length > 0 && (
                  <MainSearchResults
                    title="Лекции"
                    count={searchResults.counts.seminars}
                    resultItems={[
                      ...getResultItemsByType("seminars").slice(0, 4)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("seminar_cycles").length > 0 && (
                  <MainSearchResults
                    title="Циклы"
                    count={searchResults.counts.seminar_cycles}
                    resultItems={[
                      ...getResultItemsByType("seminar_cycles").slice(0, 4)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("grants").length > 0 && (
                  <MainSearchResults
                    title="Гранты"
                    count={searchResults.counts.grants_grants}
                    resultItems={[
                      ...getResultItemsByType("grants").slice(0, 4)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("posts").length > 0 && (
                  <MainSearchResults
                    title="Статьи"
                    count={searchResults.counts.media_posts}
                    resultItems={[...getResultItemsByType("posts").slice(0, 4)]}
                    searchTerm={searchTerm}
                    startPage={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("videos").length > 0 && (
                  <MainSearchResults
                    title="Видео"
                    count={searchResults.counts.video_videos}
                    resultItems={[
                      ...getResultItemsByType("videos").slice(0, 4)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("organizations").length > 0 && (
                  <MainSearchResults
                    title="Организации"
                    count={searchResults.counts.organizations}
                    resultItems={[
                      ...getResultItemsByType("organizations").slice(0, 3)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    compact={true}
                    hasSubscribtion={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("media_projects").length > 0 && (
                  <MainSearchResults
                    title="Проекты"
                    count={searchResults.counts.media_projects}
                    resultItems={[
                      ...getResultItemsByType("projects").slice(0, 3)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    compact={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("presenters").length > 0 && (
                  <MainSearchResults
                    title="Преподаватели"
                    count={searchResults.counts.presenters}
                    resultItems={[
                      ...getResultItemsByType("presenters").slice(0, 3)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    compact={true}
                    hasSubscribtion={true}
                    onResultClick={onResultClick}
                  />
                )}
              {searchResults.objects &&
                getResultItemsByType("places").length > 0 && (
                  <MainSearchResults
                    title="Места"
                    count={searchResults.counts.places}
                    resultItems={[
                      ...getResultItemsByType("places").slice(0, 3)
                    ]}
                    searchTerm={searchTerm}
                    startPage={true}
                    compact={true}
                    onResultClick={onResultClick}
                  />
                )}

              <div
                className="text-center"
                style={{ padding: "50px 0 400px 0" }}
              >
                <a className="btn-small" onClick={onClearClick}>
                  Закрыть результаты поиска
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

MainHeader.defaultProps = {
  userAvatar: "",
  feedsItems: "0",
  isUserLoggedIn: false
};

MainHeader.propTypes = {
  userAvatar: PropTypes.string,
  feedsItems: PropTypes.string,
  isUserLoggedIn: PropTypes.bool
};
