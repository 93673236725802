import { PureComponent } from "react";
import ReactDOM from "react-dom";

export default class Overlay extends PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.overlayRoot = document.getElementById("overlay-root");
    this.overlay = document.querySelectorAll(
      ".main_header-toggle_menu-overlay"
    );
  }

  componentDidMount() {
    const {
      props: { withOverflowed }
    } = this;
    this.overlayRoot.appendChild(this.el);
    if (withOverflowed) {
      document.querySelector("html").classList.add("toggle_menu-open");
      document.querySelector("body").classList.add("toggle_menu-open");
      document.querySelector("html").classList.add("overflowed");
      document.querySelector("body").classList.add("overflowed");
    }
  }

  componentWillUnmount() {
    const {
      props: { withOverflowed }
    } = this;
    this.overlayRoot.removeChild(this.el);
    if (withOverflowed) {
      document.querySelector("html").classList.remove("toggle_menu-open");
      document.querySelector("body").classList.remove("toggle_menu-open");
      document.querySelector("html").classList.remove("overflowed");
      document.querySelector("body").classList.remove("overflowed");
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
