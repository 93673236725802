import { Suspense, lazy } from "react";

const CourseDates = lazy(() => import("./CourseDates"));

const CourseDatesWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <CourseDates {...props} />
    </Suspense>
  );
};

export default CourseDatesWrapper;
