/* eslint-disable */

window.analytics = {
  pageview: function(url) {
    this.googlePageview(url);
    this.yandexPageview(url);
  },
  track: function(event, properties) {
    this.mixpanelTrack(event, properties);
    this.googleTrack(properties.category, event, properties.label, properties.value, properties.noninteraction);
  },
  yandexPageview: function(url) {
    if (window.yaCounter) {
      window.yaCounter.hit(url);
    }
  },
  googlePageview: function() {
    if (window.ga) {
      ga('set', 'contentGroup1', 'current');
      ga('send', 'pageview', location.pathname + location.search);
    }
  },
  googleTrack: function(category, action, label, value, noninteraction) {
    if (category == null) {
      category = "All";
    }
    if (window.ga) {
      ga('send', 'event', category, action, label, value, {
        'nonInteraction': noninteraction
      });
    }
  },
  mixpanelTrack: function(event, properties) {
    if (window.mixpanel) {
      window.mixpanel.track(event, properties);
    }
  }
};

document.addEventListener("page:change", function() {
  window.analytics.pageview();
});

document.addEventListener("page:nextPage", function(event, data) {
  window.analytics.pageview(data.url);
});
