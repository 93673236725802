import "react-app-polyfill/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/array/for-each";
import "core-js/features/url-search-params";
import smoothscroll from "smoothscroll-polyfill";
import ReactDOM from "react-dom";
import Comments from "./components/Comments";
import Autocomplete from "./components/Autocomplete";
import AutocompleteByCategory from "./components/AutocompleteByCategory";
import TagsAutocomplete from "./components/TagsAutocomplete";
import RelatedAnnounces from "./components/RelatedAnnounces";
import RelatedTags from "./components/RelatedTags";
import UsersAutocomplete from "./components/UsersAutocomplete";
import PresentableEditors from "./components/PresentableEditors";
import ImageuploadInput from "./components/ImageuploadInput";
import ProjectPreview from "./components/ProjectPreview";
import PricePicker from "./components/PricePicker";
import VkShareButton from "./components/VkShareButton";
import FbShareButton from "./components/FbShareButton";
import MainHeader from "./components/MainHeader";
// import StartPageSearch from "./components/StartPageSearch";
import Dropzone from "./components/Dropzone";
import Tooltip from "./components/Tooltip";
import ImageZoom from "./components/ImageZoom";
import OrganizationFeedbackModal from "./components/OrganizationFeedbackModal";
import CollectionSelect from "./components/CollectionSelect";
import ParticipantsAccordion from "./components/ParticipantsAccordion";
import ParticipantsModal from "./components/ParticipantsModal";
import Interview from "./components/Interview";
import AdminFilterModal from "./components/AdminFilterModal";
import PromoRequest from "./components/PromoRequest";
import InterviewModal from "./components/InterviewModal";
import DisclaimerModal from "./components/DisclaimerModal";
import InterviewUnregisterModal from "./components/InterviewUnregisterModal";
import InterviewRegisterModal from "./components/InterviewRegisterModal";
import MaterialsUploader from "./components/MaterialsUploader";
import CategoryFilter from "./components/CategoryFilter";
import SimpleCategoryFilter from "./components/SimpleCategoryFilter";
import CategoryInput from "./components/CategoryInput";
import AutocompleteAnounces from "./components/AutocompleteAnounces";
import FlashMessages from "./components/FlashMessages";
import DayFilter from "./components/DayFilter";
import GrantDates from "./components/GrantDates";
import SeminarDates from "./components/SeminarDates";
import CourseDates from "./components/CourseDates";
import StudyReasons from "./components/StudyReasons";
import ShowAllBtn from "./components/ShowAllBtn";
import UsernameField from "./components/UsernameField";
import UserPhoneField from "./components/UserPhoneField";
import DayInput from "./components/DayInput";

smoothscroll.polyfill();
const LazyLoad = require("./vendor/lazyload");

require("./vendor/turbolinks");

window.Turbolinks.pagesCached(3);

function addListenerMulti(element, eventNames, fn) {
  eventNames.split(" ").forEach(e => element.addEventListener(e, fn, false));
}

function initLazyLoad() {
  if (!window.applicationLazyLoad) {
    // eslint-disable-next-line
    window.applicationLazyLoad = new LazyLoad({
      threshold: 600,
      skip_invisible: false,
      data_src: "original"
    });
    try {
      const event = new CustomEvent("scroll");
      window.dispatchEvent(event);
    } catch (e) {
      $(window).trigger("scroll");
    }
  } else {
    window.applicationLazyLoad.update();
  }
}

addListenerMulti(document, "DOMContentLoaded page:load modal:open", () => {
  initLazyLoad();

  const CommentsNodes = document.querySelectorAll(
    '[react-component="Comments"]'
  );
  const AutocompleteNodes = document.querySelectorAll(
    '[react-component="Autocomplete"]'
  );
  const TagsAutocompleteNodes = document.querySelectorAll(
    '[react-component="TagsAutocomplete"]'
  );
  const RelatedAnnouncesNodes = document.querySelectorAll(
    '[react-component="RelatedAnnounces"]'
  );
  const RelatedTagsNodes = document.querySelectorAll(
    '[react-component="RelatedTags"]'
  );
  const ImageuploadInputNodes = document.querySelectorAll(
    '[react-component="ImageuploadInput"]'
  );
  const MainHeaderNodes = document.querySelectorAll(
    '[react-component="MainHeader"]'
  );
  const InterviewNode = document.querySelector('[react-component="Interview"]');
  const PromoRequestNode = document.querySelector(
    '[react-component="PromoRequest"]'
  );
  const CourseDatesNode = document.querySelector(
    '[react-component="CourseDates"]'
  );
  const StudyReasonsNode = document.querySelector(
    '[react-component="StudyReasons"]'
  );
  const SeminarDatesNode = document.querySelector(
    '[react-component="SeminarDates"]'
  );
  const GrantDatesNode = document.querySelector(
    '[react-component="GrantDates"]'
  );
  const ImageZoomNodes = document.querySelectorAll(
    '[react-component="ImageZoom"]'
  );
  const VkShareButtonNodes = document.querySelectorAll(
    '[react-component="VkShareButton"]'
  );
  const FbShareButtonNodes = document.querySelectorAll(
    '[react-component="FbShareButton"]'
  );
  const AdminFilterModalNodes = document.querySelectorAll(
    '[react-component="AdminFilterModal"]'
  );
  const CategoryFilterNode = document.querySelector(
    '[react-component="CategoryFilter"]'
  );
  const SimpleCategoryFilterNode = document.querySelector(
    '[react-component="SimpleCategoryFilter"]'
  );
  const FlashMessagesNode = document.querySelector(
    '[react-component="FlashMessages"]'
  );
  const CategoryInputNode = document.querySelector(
    '[react-component="CategoryInput"]'
  );
  const UserPhoneFieldNode = document.querySelector(
    '[react-component="UserPhoneField"]'
  );
  const PricePickerNode = document.querySelector(
    '[react-component="PricePicker"]'
  );
  const DropzoneNodes = document.querySelectorAll(
    '[react-component="Dropzone"]'
  );
  const MaterialsUploaderNodes = document.querySelectorAll(
    '[react-component="MaterialsUploader"]'
  );
  const AutocompleteAnouncesNode = document.querySelector(
    '[react-component="AutocompleteAnounces"]'
  );
  const CollectionSelectNodes = document.querySelectorAll(
    '[react-component="CollectionSelect"]'
  );
  const TooltipNodes = document.querySelectorAll('[react-component="Tooltip"]');
  const OrganizationFeedbackModalNodes = document.querySelectorAll(
    '[react-component="OrganizationFeedbackModal"]'
  );
  const ProjectPreviewNode = document.querySelector(
    '[react-component="ProjectPreview"]'
  );
  const AutocompleteByCategoryNodes = document.querySelectorAll(
    '[react-component="AutocompleteByCategory"]'
  );
  const PresentableEditorsNode = document.querySelector(
    '[react-component="PresentableEditors"]'
  );
  const UsersAutocompleteNode = document.querySelector(
    '[react-component="UsersAutocomplete"]'
  );
  const DayFilterNode = document.querySelector('[react-component="DayFilter"]');
  const UsernameFieldNode = document.querySelector(
    '[react-component="UsernameField"]'
  );
  const ParticipantsAccordionNode = document.querySelector(
    '[react-component="ParticipantsAccordion"]'
  );
  const DayInputNodes = document.querySelectorAll(
    '[react-component="DayInput"]'
  );

  if (DayInputNodes) {
    DayInputNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <DayInput
          inputName={node.dataset.inputName}
          value={node.dataset.value === "null" ? "" : node.dataset.value}
          placeholder={node.dataset.placeholder}
        />,
        node
      )
    );
  }

  if (ParticipantsAccordionNode) {
    ReactDOM.render(
      <ParticipantsAccordion
        data={ParticipantsAccordionNode.dataset.data}
        totalCount={ParticipantsAccordionNode.dataset.totalCount}
        orderBy={ParticipantsAccordionNode.dataset.orderBy}
        page={ParticipantsAccordionNode.dataset.page}
        url={ParticipantsAccordionNode.dataset.url}
      />,
      ParticipantsAccordionNode
    );
  }

  if (UsernameFieldNode) {
    ReactDOM.render(
      <UsernameField
        usernameLabel={UsernameFieldNode.dataset.usernameLabel}
        errors={UsernameFieldNode.dataset.errors}
        username={UsernameFieldNode.dataset.username}
      />,
      UsernameFieldNode
    );
  }

  if (DayFilterNode) {
    ReactDOM.render(
      <DayFilter
        selectedDate={DayFilterNode.dataset.selectedDate}
        params={DayFilterNode.dataset.params}
        url={DayFilterNode.dataset.url}
      />,
      DayFilterNode
    );
  }

  if (UsersAutocompleteNode) {
    ReactDOM.render(
      <UsersAutocomplete inputName={UsersAutocompleteNode.dataset.inputName} />,
      UsersAutocompleteNode
    );
  }

  if (PresentableEditorsNode) {
    ReactDOM.render(
      <PresentableEditors type={PresentableEditorsNode.dataset.type} />,
      PresentableEditorsNode
    );
  }

  if (AutocompleteByCategoryNodes) {
    AutocompleteByCategoryNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <AutocompleteByCategory
          isInputEmpty={node.dataset.isInputEmpty}
          fields={node.dataset.fields}
          attributeName={node.dataset.attributeName}
          addBlank={node.dataset.addBlank}
          category={node.dataset.category}
        />,
        node
      )
    );
  }

  if (ProjectPreviewNode) {
    ReactDOM.render(
      <ProjectPreview
        images={ProjectPreviewNode.dataset.images}
        modelName={ProjectPreviewNode.dataset.modelName}
        attrName={ProjectPreviewNode.dataset.attrName}
        name={ProjectPreviewNode.dataset.name}
        description={ProjectPreviewNode.dataset.description}
        contributors={ProjectPreviewNode.dataset.contributors}
        textColor={ProjectPreviewNode.dataset.textColor}
        backgroundColor={ProjectPreviewNode.dataset.backgroundColor}
      />,
      ProjectPreviewNode
    );
  }

  if (OrganizationFeedbackModalNodes) {
    OrganizationFeedbackModalNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <OrganizationFeedbackModal
          organizationId={node.dataset.organizationId}
          announceId={node.dataset.announceId}
          announceType={node.dataset.announceType}
        />,
        node
      )
    );
  }

  if (TooltipNodes) {
    TooltipNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <Tooltip
          iconClass={node.dataset.iconClass}
          position={node.dataset.position}
          title={node.dataset.title}
          text={node.dataset.text}
          width={node.dataset.width}
        />,
        node
      )
    );
  }

  if (CollectionSelectNodes) {
    CollectionSelectNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <CollectionSelect
          name={node.dataset.name}
          init={node.dataset.init}
          options={node.dataset.options}
          placeholder={node.dataset.placeholder}
        />,
        node
      )
    );
  }

  if (AutocompleteAnouncesNode) {
    ReactDOM.render(
      <AutocompleteAnounces fields={AutocompleteAnouncesNode.dataset.fields} />,
      AutocompleteAnouncesNode
    );
  }

  if (MaterialsUploaderNodes) {
    MaterialsUploaderNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <MaterialsUploader
          formName={node.dataset.formName}
          inputName={node.dataset.inputName}
          inputController={node.dataset.inputController}
          images={node.dataset.images}
          fields={node.dataset.fields}
        />,
        node
      )
    );
  }

  if (DropzoneNodes) {
    DropzoneNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <Dropzone
          isTemp={node.dataset.isTemp}
          inputName={node.dataset.inputName}
          customInputName={node.dataset.customInputName}
          inputController={node.dataset.inputController}
          inputHint={node.dataset.inputHint}
          image={node.dataset.image}
          fields={node.dataset.fields}
          dropzoneClass={node.dataset.dropzoneClass}
        />,
        node
      )
    );
  }

  if (PricePickerNode) {
    ReactDOM.render(
      <PricePicker
        tickets={JSON.parse(PricePickerNode.dataset.tickets)}
        modelName={PricePickerNode.dataset.modelName}
        attrName={PricePickerNode.dataset.attrName}
        payMode={PricePickerNode.dataset.payMode}
        priceValue={PricePickerNode.dataset.priceValue}
      />,
      PricePickerNode
    );
  }

  if (UserPhoneFieldNode) {
    ReactDOM.render(
      <UserPhoneField
        phone={UserPhoneFieldNode.dataset.phone}
        phoneLabel={UserPhoneFieldNode.dataset.phoneLabel}
        inputName={UserPhoneFieldNode.dataset.inputName}
      />,
      UserPhoneFieldNode
    );
  }

  if (CategoryInputNode) {
    ReactDOM.render(
      <CategoryInput
        inputName={CategoryInputNode.dataset.inputName}
        selectedCategories={CategoryInputNode.dataset.selectedCategories}
        categories={CategoryInputNode.dataset.categories}
        withOnlySeminars={JSON.parse(
          CategoryInputNode.dataset.withOnlySeminars
        )}
        isPrivilegedUser={JSON.parse(
          CategoryInputNode.dataset.isPrivilegedUser
        )}
      />,
      CategoryInputNode
    );
  }

  if (FlashMessagesNode) {
    ReactDOM.render(
      <FlashMessages
        messages={JSON.parse(FlashMessagesNode.dataset.messages)}
        allowTypes={JSON.parse(FlashMessagesNode.dataset.allowTypes)}
      />,
      FlashMessagesNode
    );
  }

  if (CategoryFilterNode) {
    ReactDOM.render(
      <CategoryFilter
        categories={CategoryFilterNode.dataset.categories}
        selectedCategories={CategoryFilterNode.dataset.selectedCategories}
        rootPath={CategoryFilterNode.dataset.rootPath}
        city={CategoryFilterNode.dataset.city}
        purpose={CategoryFilterNode.dataset.purpose}
      />,
      CategoryFilterNode
    );
  }

  if (SimpleCategoryFilterNode) {
    ReactDOM.render(
      <SimpleCategoryFilter
        categories={SimpleCategoryFilterNode.dataset.categories}
        selectedCategories={SimpleCategoryFilterNode.dataset.selectedCategories}
        rootPath={SimpleCategoryFilterNode.dataset.rootPath}
        city={SimpleCategoryFilterNode.dataset.city}
        purpose={SimpleCategoryFilterNode.dataset.purpose}
      />,
      SimpleCategoryFilterNode
    );
  }

  if (AdminFilterModalNodes) {
    AdminFilterModalNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <AdminFilterModal
          allColumns={JSON.parse(node.dataset.allColumns)}
          columns={JSON.parse(node.dataset.columns)}
          defaultColumns={JSON.parse(node.dataset.defaultColumns)}
          url={node.dataset.url}
          btnText={node.dataset.btnText}
          callback={node.dataset.callback}
        />,
        node
      )
    );
  }

  if (VkShareButtonNodes) {
    VkShareButtonNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(<VkShareButton shareUrl={node.dataset.shareUrl} />, node)
    );
  }

  if (FbShareButtonNodes) {
    FbShareButtonNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <FbShareButton
          shareUrl={node.dataset.shareUrl}
          shareCount={node.dataset.shareCount}
        />,
        node
      )
    );
  }

  if (ImageZoomNodes) {
    ImageZoomNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <ImageZoom
          src={node.dataset.src}
          srcZoom={node.dataset.srcZoom}
          alt={node.dataset.alt}
        />,
        node
      )
    );
  }

  if (GrantDatesNode) {
    ReactDOM.render(
      <GrantDates
        value={GrantDatesNode.dataset.value}
        isNoDeadline={JSON.parse(GrantDatesNode.dataset.isNoDeadline)}
      />,
      GrantDatesNode
    );
  }

  if (SeminarDatesNode) {
    ReactDOM.render(
      <SeminarDates dates={JSON.parse(SeminarDatesNode.dataset.dates)} />,
      SeminarDatesNode
    );
  }

  if (CourseDatesNode) {
    ReactDOM.render(
      <CourseDates dates={JSON.parse(CourseDatesNode.dataset.dates)} />,
      CourseDatesNode
    );
  }

  if (StudyReasonsNode) {
    ReactDOM.render(
      <StudyReasons reasons={JSON.parse(StudyReasonsNode.dataset.reasons)} />,
      StudyReasonsNode
    );
  }

  if (PromoRequestNode) {
    ReactDOM.render(
      <PromoRequest
        interviewId={PromoRequestNode.dataset.interviewId}
        userId={PromoRequestNode.dataset.userId}
        paymentableId={parseInt(PromoRequestNode.dataset.paymentableId, 10)}
        paymentableType={PromoRequestNode.dataset.paymentableType}
        payableServices={JSON.parse(PromoRequestNode.dataset.payableServices)}
        fbTrackLeadScript={PromoRequestNode.dataset.fbTrackLeadScript}
        payService={
          PromoRequestNode.dataset.payService
            ? PromoRequestNode.dataset.payService
            : ''
        }
        withAdditionalFields={
          PromoRequestNode.dataset.withAdditionalFields
            ? JSON.parse(PromoRequestNode.dataset.withAdditionalFields)
            : false
        }
        withSearch={
          PromoRequestNode.dataset.withSearch
            ? JSON.parse(PromoRequestNode.dataset.withSearch)
            : false
        }
        withPromocode={
          PromoRequestNode.dataset.withPromocode
            ? JSON.parse(PromoRequestNode.dataset.withPromocode)
            : false
        }
        withText={
          PromoRequestNode.dataset.withText
            ? JSON.parse(PromoRequestNode.dataset.withText)
            : true
        }
        onlyOnline={
          PromoRequestNode.dataset.onlyOnline
            ? JSON.parse(PromoRequestNode.dataset.onlyOnline)
            : false
        }
      />,
      PromoRequestNode
    );
  }

  if (InterviewNode) {
    ReactDOM.render(
      <Interview
        interviewId={InterviewNode.dataset.interviewId}
        modelName={InterviewNode.dataset.modelName}
        types={JSON.parse(InterviewNode.dataset.types)}
        answerTypes={JSON.parse(InterviewNode.dataset.answerTypes)}
        answerVariants={JSON.parse(InterviewNode.dataset.answerVariants)}
        questions={JSON.parse(InterviewNode.dataset.questions)}
        isInner={JSON.parse(InterviewNode.dataset.isInner)}
      />,
      InterviewNode
    );
  }

  if (InterviewNode) {
    ReactDOM.render(
      <Interview
        interviewId={InterviewNode.dataset.interviewId}
        modelName={InterviewNode.dataset.modelName}
        types={JSON.parse(InterviewNode.dataset.types)}
        answerTypes={JSON.parse(InterviewNode.dataset.answerTypes)}
        answerVariants={JSON.parse(InterviewNode.dataset.answerVariants)}
        questions={JSON.parse(InterviewNode.dataset.questions)}
        isInner={JSON.parse(InterviewNode.dataset.isInner)}
      />,
      InterviewNode
    );
  }

  if (MainHeaderNodes) {
    MainHeaderNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <MainHeader
          userBarLinks={node.dataset.userBarLinks}
          toggleMenuLinks={node.dataset.toggleMenuLinks}
          headerMenuLinks={node.dataset.headerMenuLinks}
          isUserLoggedIn={!!window.current_user || false}
          userAvatar={window.current_user ? window.current_user.userpic : ""}
          feedsItems={node.dataset.feedsItems}
          bannerUrl={node.dataset.bannerUrl}
          bannerImage={node.dataset.bannerImage}
        />,
        node
      )
    );
  }

  if (CommentsNodes) {
    CommentsNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <Comments
          user={JSON.parse(node.dataset.user)}
          url={node.dataset.url}
          canToggle={JSON.parse(node.dataset.canToggle)}
        />,
        node
      )
    );
  }

  if (AutocompleteNodes) {
    AutocompleteNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <Autocomplete
          isInputEmpty={JSON.parse(node.dataset.isInputEmpty)}
          isMultiple={JSON.parse(node.dataset.isMultiple)}
          addBlank={JSON.parse(node.dataset.addBlank)}
          fields={JSON.parse(node.dataset.fields)}
          modelName={node.dataset.modelName}
          attributeName={node.dataset.attributeName}
          logoField={node.dataset.logoField}
          descriptionField={node.dataset.descriptionField}
          editorsField={node.dataset.editorsField}
          cities={node.dataset.cities}
          mainOrganizationId={node.dataset.mainOrganizationId}
          presentersType={node.dataset.presentersType}
          custom={node.dataset.custom}
        />,
        node
      )
    );
  }

  if (TagsAutocompleteNodes) {
    TagsAutocompleteNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <TagsAutocomplete
          init={node.dataset.init}
          name={node.dataset.name}
          type={node.dataset.type}
        />,
        node
      )
    );
  }

  if (ImageuploadInputNodes) {
    ImageuploadInputNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <ImageuploadInput
          isMultiple={JSON.parse(node.dataset.isMultiple)}
          isTemp={JSON.parse(node.dataset.isTemp)}
          formName={node.dataset.formName}
          inputName={node.dataset.inputName}
          inputController={node.dataset.inputController}
          images={node.dataset.images}
          fields={node.dataset.fields}
          additionalFields={node.dataset.additionalFields}
        />,
        node
      )
    );
  }

  if (RelatedAnnouncesNodes) {
    RelatedAnnouncesNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <RelatedAnnounces
          tags={node.dataset.tags}
          postId={node.dataset.postId}
          relatedAnnounces={node.dataset.relatedAnnounces}
          inputName={node.dataset.inputName}
          slotsCount={node.dataset.slotsCount}
          onlyPosts={node.dataset.onlyPosts}
          showAutocomplete={node.dataset.showAutocomplete}
          showCode={node.dataset.showCode}
        />,
        node
      )
    );
  }

  if (RelatedTagsNodes) {
    RelatedTagsNodes.forEach(node =>
      // eslint-disable-next-line
      ReactDOM.render(
        <RelatedTags
          tags={node.dataset.tags}
          postId={node.dataset.postId}
          relatedAnnounces={node.dataset.relatedAnnounces}
          relatedPosts={node.dataset.relatedPosts}
          paramName={node.dataset.paramName}
        />,
        node
      )
    );
  }
});

addListenerMulti(document, "page:restore", () => {
  initLazyLoad();
});

addListenerMulti(
  document,
  "DOMContentLoaded page:load scrollY reload:participants",
  () => {
    const ParticipantsModalNodes = document.querySelectorAll(
      '[react-component="ParticipantsModal"]'
    );
    const DisclaimerModalNodes = document.querySelectorAll(
      '[react-component="DisclaimerModal"]'
    );
    const InterviewModalNodes = document.querySelectorAll(
      '[react-component="InterviewModal"]'
    );

    const InterviewUnregisterModalNodes = document.querySelectorAll(
      '[react-component="InterviewUnregisterModal"]'
    );

    const InterviewRegisterModalNodes = document.querySelectorAll(
      '[react-component="InterviewRegisterModal"]'
    );
    const ShowAllBtnNodes = document.querySelectorAll(
      '[react-component="ShowAllBtn"]'
    );

    if (ParticipantsModalNodes) {
      ParticipantsModalNodes.forEach(node =>
        // eslint-disable-next-line
        ReactDOM.render(
          <ParticipantsModal
            participants={node.dataset.participants}
            showMoreUrl={node.dataset.showMoreUrl}
            showWithoutFirst={node.dataset.showWithoutFirst}
          />,
          node
        )
      );
    }

    if (DisclaimerModalNodes) {
      DisclaimerModalNodes.forEach(node =>
        // eslint-disable-next-line
        ReactDOM.render(
          <DisclaimerModal
            text={node.dataset.text}
            showOnCreate={node.dataset.showOnCreate}
          />,
          node
        )
      );
    }

    if (InterviewModalNodes) {
      InterviewModalNodes.forEach(node =>
        // eslint-disable-next-line
        ReactDOM.render(
          <InterviewModal
            caption={node.dataset.caption}
            btnClass={node.dataset.btnClass}
            resourceId={node.dataset.resourceId}
            date={node.dataset.date}
            dates={node.dataset.dates}
            url={node.dataset.url}
            questions={node.dataset.questions}
            answerVariants={node.dataset.answerVariants}
          />,
          node
        )
      );
    }

    if (InterviewUnregisterModalNodes) {
      InterviewUnregisterModalNodes.forEach(node =>
        // eslint-disable-next-line
        ReactDOM.render(
          <InterviewUnregisterModal
            caption={node.dataset.caption}
            btnClass={node.dataset.btnClass}
            dates={node.dataset.dates}
            url={node.dataset.url}
          />,
          node
        )
      );
    }

    if (InterviewRegisterModalNodes) {
      InterviewRegisterModalNodes.forEach(node =>
        // eslint-disable-next-line
        ReactDOM.render(
          <InterviewRegisterModal
            caption={node.dataset.caption}
            more={node.dataset.more}
            btnClass={node.dataset.btnClass}
            dates={node.dataset.dates}
            url={node.dataset.url}
          />,
          node
        )
      );
    }

    if (ShowAllBtnNodes) {
      ShowAllBtnNodes.forEach(node =>
        // eslint-disable-next-line
        ReactDOM.render(
          <ShowAllBtn id={node.dataset.id} text={node.dataset.text} />,
          node
        )
      );
    }
  }
);

require("./javascripts/statistics/analytics");
require("./javascripts/statistics/init_mixpanel");
require("./javascripts/statistics/init_google_analytics");
require("./javascripts/statistics/init_tns-metrix");
require("./javascripts/statistics/init-yandex-metrika");
