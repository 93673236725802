import ImageZoom from "react-medium-image-zoom";

export default function ReactImageZoom({ src, srcZoom, alt }) {
  return (
    <ImageZoom
      image={{
        src,
        alt
      }}
      zoomImage={{
        src: srcZoom,
        alt
      }}
      onZoom={() =>
        document.querySelector("body").classList.add("zoom-overlay-open")
      }
      onUnzoom={() =>
        document.querySelector("body").classList.remove("zoom-overlay-open")
      }
    />
  );
}
