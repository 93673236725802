import { Suspense, lazy } from "react";
import PropTypes from "prop-types";

const Comments = lazy(() => import("./Comments"));

const LoadingComments = (
  <a href={`${window.location}`} className="link_show_all">
    Показать комментарии
  </a>
);

const CommentsWrapper = ({ url, canToggle, user }) => {
  return (
    <Suspense fallback={LoadingComments}>
      <Comments url={url} user={user} canToggle={canToggle} />
    </Suspense>
  );
};

export default CommentsWrapper;

CommentsWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  canToggle: PropTypes.bool.isRequired
};
