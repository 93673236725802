import { Suspense, lazy } from "react";

const AutocompleteByCategory = lazy(() => import("./AutocompleteByCategory"));

const AutocompleteByCategoryWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <AutocompleteByCategory {...props} />
    </Suspense>
  );
};

export default AutocompleteByCategoryWrapper;
