import { PureComponent } from "react";

export default class ImageuploadInputTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAdditional: false
    };
  }

  getValue = (image, fieldName) => {
    if (fieldName === "description") {
      if (image.description && image.description.length > 0) {
        return image.description;
      }
      return undefined;
    }
    if (image.code && image.code.length > 0) {
      return image.code;
    }
    return undefined;
  };

  toggleAdditional = () => {
    const { state: { showAdditional } } = this;
    this.setState({ showAdditional: !showAdditional });
  };

  render() {
    const {
      state: { showAdditional },
      props: {
        image,
        fields,
        additionalFields,
        inputName,
        inputController,
        isMultiple
      }
    } = this;

    return (
      <div className="imageupload-tabs">
        <div className="imageupload-tab-main">
          <input
            id="images_id"
            name={`${inputName}[][id]`}
            type="hidden"
            defaultValue={image.id}
          />
          {image.uploaded && (
            <div>
              <ul>
                {fields.map((field, index) => (
                  // eslint-disable-next-line
                  <li className="input" key={index}>
                    <label htmlFor={`${inputName}_${image.id}`}>
                      {field.label}
                    </label>
                    <input
                      className="string"
                      id={`${inputName}_${image.id}`}
                      name={`${inputName}[][${field.name}]`}
                      defaultValue={this.getValue(image, field.name)}
                      autoComplete="off"
                    />
                  </li>
                ))}
              </ul>
              {isMultiple && (
                <div className="is_inside_image">
                  <label htmlFor={`is_inside_${inputController}_${image.id}`}>
                    Внутренняя картинка
                  </label>
                  <input
                    id={`is_inside_${inputController}_${image.id}`}
                    name={'is_inside'}
                    type="radio"
                    value={image.id}
                    defaultChecked={image.is_inside}
                    readOnly={true}
                  />
                </div>
              )}
            </div>
          )}
          {additionalFields.length > 0 &&
            !showAdditional && (
              <div
                className="toggle_additional"
                title="Показать дополнительные свойства"
                onClick={() => this.toggleAdditional()}
              />
            )}
        </div>
        {additionalFields.length > 0 &&
          showAdditional && (
            <div className="imageupload-tab-additional">
              <ul>
                {additionalFields.map((field, index) => (
                  // eslint-disable-next-line
                  <li key={index}>
                    <label htmlFor={`${inputName}_${image.id}`}>
                      {field.label}
                    </label>
                    <input
                      className="string"
                      id={`${inputName}_${image.id}`}
                      name={`${inputName}[][${field.name}]`}
                      defaultValue={image[field.name]}
                      autoComplete="off"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
      </div>
    );
  }
}
