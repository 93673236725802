import { Suspense, lazy } from "react";

const AdminFilterModal = lazy(() => import("./AdminFilterModal"));

const AdminFilterModalWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <AdminFilterModal {...props} />
    </Suspense>
  );
};

export default AdminFilterModalWrapper;
