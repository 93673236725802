import { Suspense, lazy } from "react";

const VkShareButton = lazy(() => import("./VkShareButton"));

const VkShareButtonWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <VkShareButton {...props} />
    </Suspense>
  );
};

export default VkShareButtonWrapper;
