import { useState } from "react";
import PropTypes from "prop-types";
import TextInput from "../FormInputs/TextInput";

export default function UserPhoneField({ phone, phoneLabel, inputName }) {
  const [phoneState, setPhoneState] = useState(phone);

  const onFieldChange = value => {
    setPhoneState(value);
  };

  return (
    <div className="string">
      <TextInput
        type="tel"
        placeholder={phoneLabel}
        value={phoneState || ""}
        onChange={onFieldChange}
      />
      <input name={inputName} type="hidden" value={phoneState || ""} />
    </div>
  );
}

UserPhoneField.propTypes = {
  phone: PropTypes.string.isRequired,
  phoneLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired
};
