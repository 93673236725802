import { Suspense, lazy } from "react";

const StudyReasons = lazy(() => import("./StudyReasons"));

const StudyReasonsWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <StudyReasons {...props} />
    </Suspense>
  );
};

export default StudyReasonsWrapper;
