import { PureComponent } from "react";
import Textarea from "react-textarea-autosize";
import cn from "classnames";
import DropzoneComponent from "react-dropzone-component";
import axios from "../../utils/axios";
import { getLabel, toPlainText } from "../../helpers/AutocompleteHelper";

export default class AutocompleteFormItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.searchTerm ? this.props.searchTerm : "",
      maxLength: this.props.maxLength,
      currentLength: 0,
      logoId: undefined
    };
  }

  // eslint-disable-next-line
  onChange = ({ target: { value } }) => {
    const {
      state: { maxLength }
    } = this;
    const length = toPlainText(value).length;
    if (length > maxLength) {
      return false;
    }
    this.setState({ value, currentLength: length });
  };

  onSuccess(logoId) {
    this.setState({ logoId });
  }

  onRemovedfile() {
    const {
      state: { logoId },
      props: { logoField }
    } = this;
    axios.delete(`/${logoField}s/${logoId}`).then(({ data }) => {
      if (data && data.destroy) {
        this.setState({ logoId: "" });
      }
    });
  }

  hasError = (fieldName, errors) => {
    if (errors && Object.prototype.hasOwnProperty.call(errors, fieldName)) {
      return true;
    }
    return false;
  };

  render() {
    const {
      state: { currentLength, value, logoId },
      props: {
        fieldName,
        modelName,
        rows,
        maxLength,
        logoField,
        cities,
        errors,
        noLabel
      }
    } = this;

    const componentConfig = { postUrl: `/${logoField}s` };
    const djsConfig = {
      acceptedFiles: "image/jpeg,image/png",
      paramName: `${logoField}[image]`,
      dictDefaultMessage: "Перетащите или нажмите",
      dictRemoveFile: "Удалить",
      dictCancelUpload: "Удалить",
      dictFileTooBig:
        "Файл слишком большой({{filesize}})MiB. Максимальный размер файла: {{maxFilesize}}MiB.",
      dictInvalidFileType: "Вы не можете загружать файлы такого типа.",
      dictCancelUploadConfirmation: "Вы уверены что хотите прервать загрузку?",
      enqueueForUpload: true,
      maxFilesize: 2,
      thumbnailWidth: 320,
      thumbnailHeight: 210,
      uploadMultiple: false,
      addRemoveLinks: true,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          ? document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content")
          : ""
      }
    };
    const eventHandlers = {
      success: (files, response) => this.onSuccess(response.id),
      removedfile: file => this.onRemovedfile(file)
    };

    if (cities) {
      return (
        <div className="form_primary-group form_primary-group_with-label">
          <div
            className={cn("input select", {
              with_error: this.hasError(fieldName, errors)
            })}
          >
            <select
              className={cn("select", { "-selected": value.length > 0 })}
              // eslint-disable-next-line
              ref="field"
              onChange={this.onChange}
            >
              <option value="">{getLabel(fieldName, modelName)}</option>
              {JSON.parse(cities).map((city, index) => (
                // eslint-disable-next-line
                <option key={index} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
            {this.hasError(fieldName, errors) && (
              <span className="error">{errors[fieldName]}</span>
            )}
          </div>
        </div>
      );
    }

    if (logoField) {
      return (
        <div>
          <label htmlFor={`${modelName}_${fieldName}`}>
            {getLabel(fieldName, modelName)}
          </label>
          <div className="form_primary-group row align-top no-wrap">
            <DropzoneComponent
              className={"dropzone-small"}
              eventHandlers={eventHandlers}
              config={componentConfig}
              djsConfig={djsConfig}
            />
            <input
              type="hidden"
              id={`${modelName}_${fieldName}`}
              value={logoId}
              // eslint-disable-next-line
              ref="field"
            />
            <div className="form_primary-group-cell form_primary-group-cell_wide">
              <span
                className="form_primary-hint form_primary-hint_no-padding"
                dangerouslySetInnerHTML={{
                  __html:
                    "Квадратное изображение в пропорции 1 к 1,<br> не более 2 мб"
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="form_primary-group form_primary-group_with-label">
        {!noLabel && (
          <label htmlFor={`${modelName}_${fieldName}`}>
            {getLabel(fieldName, modelName)}
          </label>
        )}
        <div
          className={cn("input string", {
            with_error: this.hasError(fieldName, errors)
          })}
        >
          {maxLength ? (
            <Textarea
              id={`${modelName}_${fieldName}`}
              minRows={rows}
              value={value}
              maxLength={maxLength}
              // eslint-disable-next-line
              ref="field"
              onChange={this.onChange}
            />
          ) : (
            <input
              type="text"
              value={value}
              id={`${modelName}_${fieldName}`}
              placeholder={noLabel ? getLabel(fieldName) : ""}
              // eslint-disable-next-line
              ref="field"
              onChange={this.onChange}
            />
          )}

          {this.hasError(fieldName, errors) && (
            <span className="error">{errors[fieldName]}</span>
          )}
        </div>
        {maxLength && (
          <div className="flex-grid">
            <span />
            <span className="form_primary-text_counter">
              <span className="form_primary-text_counter">{currentLength}</span>
              <span>{`/${maxLength}`}</span>
            </span>
          </div>
        )}
      </div>
    );
  }
}
