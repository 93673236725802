import { Suspense, lazy } from "react";

const SeminarDates = lazy(() => import("./SeminarDates"));

const SeminarDatesWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <SeminarDates {...props} />
    </Suspense>
  );
};

export default SeminarDatesWrapper;
