setTimeout( function() {
  (function(win, doc, cb){
    (win[cb] = win[cb] || []).push(function() {
      try {
        tnsCounterTheoryandpractice_ru = new TNS.TnsCounter({
        'account':'theoryandpractice_ru',
        'tmsec': 'theoryandpractice_total'
        });
      } catch(e){}
    });

    var tnsscript = doc.createElement('script');
    tnsscript.type = 'text/javascript';
    tnsscript.async = true;
    tnsscript.src = ('https:' == doc.location.protocol ? 'https:' : 'http:') +
      '//www.tns-counter.ru/tcounter.js';
    var s = doc.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(tnsscript, s);

    document.addEventListener("page:change", function(){
      if (window.tnsCounterTheoryandpractice_ru && tnsCounterTheoryandpractice_ru.hit) {
        tnsCounterTheoryandpractice_ru.hit();
      }
    }); //turbolinks

  })(window, document,'tnscounter_callback');
}, 200 );
