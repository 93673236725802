import { Component } from "react";
import ReactDOM from "react-dom";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.modalRoot = document.getElementById('modal-root');
    this.modal = document.querySelectorAll('.modal');
  }

  componentDidMount() {
    this.modalRoot.appendChild(this.el);
    document.querySelector("html").classList.add("modal-open");
    document.querySelector("body").classList.add("modal-open");
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
    document.querySelector("html").classList.remove("modal-open");
    document.querySelector("body").classList.remove("modal-open");
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
