export const debounce = (func, delay) => {
  let inDebounce;
  return () => {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export function getTitle(modelName, presentersType) {
  switch (modelName) {
    case "presenter":
      if (presentersType === "lecturer") {
        return "Выбранные преподаватели";
      }
      return "Выбранные спикеры";
    case "organization":
      return "Выбранные организации";
    case "place":
      return "Выбранные места";
    case "announce":
      return "Выбранные событие/курс";
    default:
      return "";
  }
}

export function getAddingTitle(modelName, presentersType) {
  switch (modelName) {
    case "presenter":
      if (presentersType === "lecturer") {
        return "преподавателя";
      }
      return "спикера";
    case "organization":
      return "организацию";
    case "place":
      return "место";
    default:
      return "";
  }
}

export function getNewTitle(modelName, presentersType) {
  switch (modelName) {
    case "presenter":
      if (presentersType === "lecturer") {
        return "Новый преподаватель";
      }
      return "Новый спикер";
    case "organization":
      return "Новая организация";
    case "place":
      return "Новое место";
    default:
      return "";
  }
}

export function getLabel(fieldName, modelName = "") {
  switch (fieldName) {
    case "name":
      return "Название";
    case "description":
      return "Подробное описание";
    case "short":
      return "Краткое описание";
    case "new_image":
      return modelName === "presenter" ? "Фотография" : "Логотип";
    case "new_phone":
      return "Телефон";
    case "address":
      return "Адрес";
    case "city_id":
      return "Выберите город";
    case "new_email":
      return "E-mail";
    case "new_website":
      return "Сайт";
    case "new_facebook":
      return "Facebook";
    case "new_vkontakte":
      return "Vkontakte";
    case "new_telegram":
      return "Telegram";
    default:
      return "";
  }
}

export function getPlaceholder(modelName, presentersType) {
  switch (modelName) {
    case "presenter":
      if (presentersType === "lecturer") {
        return "Введите имя преподавателя";
      }
      return "Введите имя спикера";
    case "organization":
      return "Введите название организации";
    case "place":
      return "Введите название места";
    case "course_course":
      return "Введите название курса";
    case "seminar":
      return "Введите название события";
    default:
      return "";
  }
}

// from imperavi
export function toPlainText(html) {
  html
    .replace(/<!--[\s\S]*?-->/gi, "")
    .replace(/<style[\s\S]*?style>/gi, "")
    .replace(/<\/p>|<\/div>|<\/li>|<\/td>/gi, "\n")
    .replace(/<\/H[1-6]>/gi, "\n\n");

  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return (tmp.textContent || tmp.innerText).trim();
}
