import cn from "classnames";

export default function AutocompleteField({
  field,
  hasMultipleFields,
  index,
  searchField,
  circle,
  modelName,
  addField,
  changeMainOrganizationId,
  mainOrganizationId
}) {
  if (searchField) {
    return (
      <div
        className="autocomplete_field_wrapper"
        onClick={() => addField(index)}
      >
        {field.image_url !== "/assets/" && (
          <img
            className={cn("autocomplete_list-image", {
              "autocomplete_list-image-circle": circle
            })}
            src={field.image_url}
            alt={field.name || ""}
          />
        )}
        {field.image_url === "/assets/" && (
          <span
            className="avatar serqul serqul-autocomplete"
            style={{ background: field.background }}
          >
            {field.name ? field.name[0] : ""}
          </span>
        )}

        <span className="autocomplete_field-name">
          {modelName === 'user' ? field.email : field.name}
        </span>
        <span className="autocomplete_field-full_name">
          {modelName === 'user' ? field.email : field.full_name}
        </span>
      </div>
    );
  }
  return (
    <a
      className="autocomplete_field"
      href={field.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {field.image_url && field.image_url !== "/assets/" && (
        <div className="autocomplete_field_image_wrapper">
          <img
            className={cn("autocomplete_field-image", {
              "autocomplete_list-image-circle": circle
            })}
            src={field.image_url}
            alt={field.name || ""}
          />
        </div>
      )}
      {field.image_url === "/assets/" && (
        <div className="autocomplete_field_image_wrapper">
          <span
            className="avatar serqul serqul-autocomplete"
            style={{ background: field.background }}
          >
            {field.name ? field.name[0] : ""}
          </span>
        </div>
      )}

      <React.Fragment>
        <span className="autocomplete_field-name">
          {field.name || field.value || field.email}
        </span>
        <span className="autocomplete_field-full_name">
          {field.full_name || field.value || field.email}
        </span>

        {modelName === "organization" && hasMultipleFields && (
          <div
            className="input autocomplete_field-checkbox"
            onClick={e => changeMainOrganizationId(e, field.id)}
          >
            {mainOrganizationId === field.id && (
              <input
                type="hidden"
                name="main_organization_id"
                value={mainOrganizationId}
              />
            )}
            <input
              className="form_primary-checkbox gray"
              type="checkbox"
              id={`checkbox_main_organization_${field.id}`}
              checked={mainOrganizationId === field.id}
              readOnly
            />
            <label
              className="control-label"
              htmlFor={`checkbox_main_organization_${field.id}`}
            >
              Основной организатор
            </label>
          </div>
        )}
      </React.Fragment>
    </a>
  );
}
