import { Suspense, lazy } from "react";

const Interview = lazy(() => import("./Interview"));

const InterviewWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загружаем">
      <Interview {...props} />
    </Suspense>
  );
};

export default InterviewWrapper;
