import { Suspense, lazy } from "react";

const Tooltip = lazy(() => import("./Tooltip"));

const TooltipWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <Tooltip {...props} />
    </Suspense>
  );
};

export default TooltipWrapper;
