import { PureComponent } from "react";

export default class UserPreview extends PureComponent {
  render() {
    const { props: { user } } = this;

    return (
      <div className="user member-item">
        <a className="content" href={user.link}>
          <span dangerouslySetInnerHTML={{ __html: user.userpic }} />
          {user.name}
          {/* <div class='username'>{user.username}</div> */}
        </a>
      </div>
    );
  }
}
