import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import TextInput from "../FormInputs/TextInput";

export default class UsernameField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username ? this.props.username : "",
      errors: this.props.errors ? JSON.parse(this.props.errors) : {}
    };
  }

  onFieldChange = (value, name) => {
    this.setState({ [name]: value });
  };

  validateAtSymbol = () => {
    const {
      state: { username }
    } = this;

    if (username.includes("@")) {
      return false;
    }
    return true;
  };

  render() {
    const {
      props: { usernameLabel },
      state: { username, errors }
    } = this;

    return (
      <div className="string">
        <label htmlFor="user_username" className="control-label">
          {usernameLabel}
          &nbsp;
          <span className="form_primary-required">*</span>
        </label>
        <Tooltip
          title="Недопустимый символ"
          position="top-start"
          animation="none"
          distance={18}
          arrow={true}
          arrowSize="small"
          open={!this.validateAtSymbol()}
        >
          <TextInput
            fieldName="username"
            value={username || ""}
            onChange={this.onFieldChange}
          />
          <input name="user[username]" type="hidden" value={username} />

          {errors && errors.username && errors.username.length > 0 && (
            <span
              className="error"
              style={{
                position: "relative",
                bottom: "22px",
                whiteSpace: "normal"
              }}
            >
              {errors.username[0]}
            </span>
          )}

          {!(errors && errors.username && errors.username.length > 0) && (
            <span
              className="error"
              style={{
                position: "relative",
                bottom: "22px",
                whiteSpace: "normal"
              }}
            >
              Логин должен содержать не меньше 4 знаков и состоять из латинских
              букв или цифр, без специальных символов (например, @).
            </span>
          )}
        </Tooltip>
      </div>
    );
  }
}

UsernameField.propTypes = {
  username: PropTypes.string.isRequired,
  usernameLabel: PropTypes.string.isRequired
};
