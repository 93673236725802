import { PureComponent } from "react";
import axios from "../../utils/axios";
import { getTAbIndexByModelName } from "../../helpers/RelatedAnnouncesHelper";

export default class RelatedAnnouncesItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      items: []
    };
  }

  componentWillMount() {
    const {
      props: { items }
    } = this;
    this.setState({ items });
  }

  hasNextPage(count) {
    const {
      state: { items }
    } = this;
    return count - items.length > 0;
  }

  handleShowMore = (count, tag) => {
    const {
      state: { page, items },
      props: { keyName }
    } = this;
    const nextPage = page + 1;
    axios(
      `/tags/${tag.id}?choose=1&tag_name=${
        tag.name
      }&type=${keyName}&page=${nextPage}`
    ).then(({ data }) => {
      if (data && data[keyName].length > 0) {
        const newItems = [...items, ...data[keyName]];
        this.setState({ items: newItems, page: nextPage });
      }
    });
  };

  render() {
    const {
      state: { items },
      props: {
        count,
        keyName,
        index,
        handleSelectRelated,
        selectedSlot,
        selectedTag,
        activeTAbIndex
      }
    } = this;

    return (
      (items.length > 0 &&
        getTAbIndexByModelName(keyName) === activeTAbIndex && (
          <div
            className="content_list"
            key={index}
            style={{ marginLeft: "-5%" }}
          >
            {items.map((item, i) => (
              <div
                className="preview_item"
                // eslint-disable-next-line
                key={i}
                style={{ width: "45%", marginLeft: "5%" }}
              >
                <a
                  className="preview_item-link"
                  href={item.url}
                  onClick={e =>
                    handleSelectRelated(
                      e,
                      selectedSlot,
                      selectedTag,
                      item.id,
                      item.klass
                    )
                  }
                >
                  <div className="preview_item-img">
                    <img src={item.image_url} alt={item.value} />
                  </div>
                  <div className="preview_item-body">
                    <h5 className="preview_item-title">{item.value}</h5>
                  </div>
                </a>
              </div>
            ))}
            {this.hasNextPage(count) && (
              <a
                className="btn-small"
                onClick={() => this.handleShowMore(count, selectedTag)}
              >
                Показать еще
              </a>
            )}
          </div>
        )) ||
      null
    );
  }
}
