import cn from "classnames";

export default function HeaderMenu({ headerMenuLinks }) {
  return (
    <React.Fragment>
      <ul className="header-menu">
        {JSON.parse(headerMenuLinks)
          .filter(l => (l.name !== "Моя лента" && l.name !== "События"))
          .map((link, index) => (
            // eslint-disable-next-line
            <li key={index} className="item">
              <a
                href={link.link}
                className={cn({
                  current_category: link.is_active
                })}
              >
                <span>{link.name}</span>
              </a>
            </li>
          ))}
      </ul>
      <a href="https://kampus.team/?utm_source=theoryandpracticeru&utm_medium=referral&utm_campaign=header_forbusiness_button" className="btn-small header-btn">
        Для бизнеса
      </a>
    </React.Fragment>
  );
}
