import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class ShowAllBtn extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowed: false
    };
  }

  toggle = event => {
    const {
      state: { isShowed },
      props: { id }
    } = this;
    event.preventDefault();
    const nodes = document.querySelectorAll(`.hide[data-id='${id}']`);
    const targetNode = event.target.closest(
      `.preview-box-platform[data-id='${id}']`
    );

    if (!isShowed) {
      nodes.forEach(node => {
        // eslint-disable-next-line
        node.style.display = "block";
        // eslint-disable-next-line
        node.style.background = "#fffacd";
        targetNode.style.background = "#fffacd";
      });
    } else {
      // eslint-disable-next-line
      nodes.forEach(node => {
        // eslint-disable-next-line
        node.style.display = "none";
        targetNode.style.background = "#fff";
      });
    }

    this.setState({ isShowed: !isShowed });
  };

  render() {
    const {
      state: { isShowed },
      props: { text }
    } = this;
    return (
      <div className="preview-box-platform-show-all" onClick={this.toggle}>
        <div className="preview-box-platform-show-all-info">
          <div className="preview-box-platform-show-all-text-text">
            <span className="preview-box-platform-show-all-text-plus">+</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: text
                }}
              />
            </span>
          </div>
        </div>
        <div className="btn btn-small btn-show-all">
          {isShowed ? "Свернуть" : "Смотреть все"}
        </div>
      </div>
    );
  }
}

ShowAllBtn.defaultProps = {
  id: "",
  text: ""
};

ShowAllBtn.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
