import { Suspense, lazy } from "react";

const ProjectPreview = lazy(() => import("./ProjectPreview"));

const ProjectPreviewWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <ProjectPreview {...props} />
    </Suspense>
  );
};

export default ProjectPreviewWrapper;
