import { PureComponent } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export default class SimpleCategoryFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: this.props.categories
        ? JSON.parse(this.props.categories)
        : [],
      superCategories: this.props.categories
        ? JSON.parse(this.props.categories).filter(
            c => c.parent_id === null && c.is_hidden === false
          )
        : [],
      selectedSuperCategories: this.props.selectedCategories
        ? JSON.parse(this.props.selectedCategories).filter(
            c => c.parent_id === null
          )
        : [],
      selectedSubCategories: this.props.selectedCategories
        ? JSON.parse(this.props.selectedCategories).filter(
            c => c.parent_id !== null
          )
        : [],
      purpose: this.props.purpose ? JSON.parse(this.props.purpose) : null
    };
  }

  getSuperCategory = (subCategory) => {
    const {
      state: { categories }
    } = this;

    return categories.filter(
      c => c.id === subCategory.parent_id
    )[0]

  }

  getSubcategoryUrl = (superCategory, subCatergory) => {
    const {
      state: { selectedSubCategories, purpose },
      props: { rootPath, city }
    } = this;
    let subCategoryPath = "";
    if ([...selectedSubCategories.map(s => s.id)].includes(subCatergory.id)) {
      const filteredSelectedSubCategories = selectedSubCategories.filter(
        c => c.id !== subCatergory.id
      );

      filteredSelectedSubCategories.forEach((fc, index) => {
        if (index === 0) {
          subCategoryPath += `${fc.path}`;
        } else {
          subCategoryPath += `-and-${fc.path}`;
        }
      });

      if (city) {
        return this.getUrlWithParams(
          `/${rootPath}/${city}/${subCategoryPath}`
        );
      }

      return this.getUrlWithParams(
        `/${rootPath}/${subCategoryPath}`
      );
    }

    selectedSubCategories.forEach((fc, index) => {
      if (index === 0) {
        subCategoryPath += `${fc.path}`;
      } else {
        subCategoryPath += `-and-${fc.path}`;
      }
    });

    if (subCategoryPath.length > 0) {
      if (purpose) {
        return this.getUrlWithParams(
          `/${rootPath}/${purpose.slug}/${superCategory.path}/${subCategoryPath}-and-${subCatergory.path}`
        );
      }

      if (city) {
        return this.getUrlWithParams(
          `/${rootPath}/${city}/${superCategory.path}/${subCategoryPath}-and-${subCatergory.path}`
        );
      }

      return this.getUrlWithParams(
        `/${rootPath}/${superCategory.path}/${subCategoryPath}-and-${subCatergory.path}`
      );
    }

    if (purpose) {
      return this.getUrlWithParams(
        `/${rootPath}/${purpose.slug}/${superCategory.path}/${subCatergory.path}`
      );
    }

    if (city) {
      return this.getUrlWithParams(
        `/${rootPath}/${city}/${superCategory.path}/${subCatergory.path}`
      );
    }

    return this.getUrlWithParams(
      `/${rootPath}/${superCategory.path}/${subCatergory.path}`
    );
  };

  getSuperCatergoryUrl = superCatergory => {
    const {
      state: { selectedSuperCategories, purpose },
      props: { rootPath, city }
    } = this;

    if (purpose) {
      if (
        [...selectedSuperCategories.map(s => s.id)].includes(superCatergory.id)
      ) {
        return this.getUrlWithParams(`/${rootPath}/${purpose.slug}/`);
      }
      return this.getUrlWithParams(
        `/${rootPath}/${purpose.slug}/${superCatergory.path}`
      );
    }

    if (
      [...selectedSuperCategories.map(s => s.id)].includes(superCatergory.id)
    ) {
      if (city) {
        return this.getUrlWithParams(`/${rootPath}/${city}`);
      }
      return this.getUrlWithParams(`/${rootPath}`);
    }

    if (city) {
      return this.getUrlWithParams(
        `/${rootPath}/${city}/${superCatergory.path}`
      );
    }

    return this.getUrlWithParams(`/${rootPath}/${superCatergory.path}`);
  };

  getUrlWithParams = path => {
    let url = path;
    if (window.location.search.length > 0) {
      url = `${path}${window.location.search}`;
    }
    return url;
  };

  getSubCategoriesBySuperCategory = superCategoryId => {
    const {
      state: { categories }
    } = this;

    return categories
      .filter(s => s.parent_id === superCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  sortedCategories = (categories, field) => {
    const sortedCategories = categories.sort((a, b) => {
      if (a[field] > b[field]) {
        return 1;
      }
      if (a[field] < b[field]) {
        return -1;
      }
      return 0;
    });

    return sortedCategories;
  };

  render() {
    const {
      state: { categories, superCategories, selectedSuperCategories, selectedSubCategories }
    } = this;

    return (
      <React.Fragment>
        <div className="form_primary p-0 filters-link">
          <div>
            { categories.filter(
                c => c.parent_id !== null
              ).sort((a, b) => a.name.localeCompare(b.name))
                .map(
                  (subCatergory, index) =>
                    subCatergory.count > 0 && (
                      <div key={subCatergory.position} className="filters-item">
                        <a
                          href={this.getSubcategoryUrl(
                            this.getSuperCategory(subCatergory),
                            subCatergory
                          )}
                          className="filters-link-category"
                        >
                          <span
                            className={cn(
                              `checkbox filters-select-item-text ${subCatergory.path}`,
                              { "-accented": subCatergory.only_seminars }
                            )}
                          >
                            <div className="input">
                              <input
                                className="form_primary-checkbox"
                                id={`subcategory_${index}`}
                                type="checkbox"
                                readOnly={true}
                                checked={[
                                  ...selectedSubCategories.map(sc => sc.id)
                                ].includes(subCatergory.id)}
                              />
                              <label htmlFor={`subcategory__${index}`}>
                                {`${subCatergory.name} (${subCatergory.count})`}
                              </label>
                            </div>
                          </span>
                        </a>
                      </div>
                    )
                )
              }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SimpleCategoryFilter.defaultProps = {
  city: "",
  purpose: ""
};

SimpleCategoryFilter.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  selectedCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  rootPath: PropTypes.string.isRequired,
  city: PropTypes.string,
  purpose: PropTypes.string
};
