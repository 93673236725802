(function() {
  window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;

  var project, projectName;

  ga('create', 'UA-7923853-1', 'theoryandpractice.ru', {'legacyCookieDomain': 'theoryandpractice.ru'});
  // To customize how analytics.js selects a cookie value when there are multiple sets of classic Google Analytics cookies, you can use the legacyCookieDomain field. The value provided to this field should match the value your classic Google Analytics tracker had used for the _setDomainName command.
  // ga('create', 'UA-XXXX-Y', 'auto', {'legacyCookieDomain': 'example.com'});
  // _gaq.push(['_setDetectFlash', false]);

  if (document.querySelector) {
    project = document.querySelector('meta[data-project]');
  };
  if (project) {
    projectName = project.getAttribute('data-project');
  };
  if (projectName) {
    ga('set', 'contentGroup3', projectName);
  };

  // ga('send', 'pageview');
  // _gaq.push(['_trackPageview']);
  // var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
  // ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
  // var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);

})();
