import { Suspense, lazy } from "react";

const ParticipantsAccordion = lazy(() => import("./ParticipantsAccordion"));

const ParticipantsAccordionWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <ParticipantsAccordion {...props} />
    </Suspense>
  );
};

export default ParticipantsAccordionWrapper;
