import { Suspense, lazy } from "react";

const PromoRequest = lazy(() => import("./PromoRequest"));

const PromoRequestWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <PromoRequest {...props} />
    </Suspense>
  );
};

export default PromoRequestWrapper;
