import { PureComponent } from "react";
import axios from "../../utils/axios";
import UserPreview from "../../components/UserPreview";

export default class UsersAutocomplete extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showUsers: false,
      usersList: [],
      selectedUser: null,
      searchTerm: "",
      inputName: this.props.inputName
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  onFocus = () => {
    const { state: { users } } = this;
    if (users.length > 0) {
      this.setState({ showUsers: true });
    }
  };

  onChange = ({ target: { value } }) => {
    this.setState({ searchTerm: value });
    axios(`/users/autocomplete_name.json?term=${value}`)
      .then(({data}) => {
        this.setState({ users: data, showUsers: true });
      })
      .catch(err => {
        console.log("parsing failed", err);
      });
  };

  submitUser = e => {
    e.preventDefault();
    const {
      state: { selectedUser, usersList, inputName },
      props: { onSubmitEditor }
    } = this;
    if (selectedUser) {
      usersList.push(selectedUser);
      if (!inputName) {
        onSubmitEditor(selectedUser.id);
      }
      this.setState({ searchTerm: "", selectedUser: null });
    }
  };

  selectUser = (e, user) => {
    e.preventDefault();
    this.setState({ searchTerm: user.username, selectedUser: user, users: [] });
  };

  deleteUser = user => {
    const { state: { usersList, inputName }, props: { onDeleteEditor } } = this;
    if (!inputName) {
      onDeleteEditor(user.id);
    }
    this.setState({ usersList: usersList.filter(item => item !== user) });
  };

  handleClickOutside = ({ target }) => {
    if (this.node.contains(target)) return;
    this.setState({ showUsers: false });
  };

  render() {
    const { state: { users, showUsers, usersList, searchTerm }, props: { inputName } } = this;

    return (
      <div
        className="form_primary-group"
        ref={node => {
          this.node = node;
        }}
      >
        {usersList.length > 0 && (
          <ul className="settings_user_organization">
            {usersList.map((user, index) => (
              // eslint-disable-next-line
              <li key={index}>
                { inputName && <input name={inputName} type="hidden" value={user.id}/> }
                <UserPreview user={user} />
                {!user.current && (
                  <div
                    className="button-destroy"
                    onClick={() => this.deleteUser(user)}
                  >
                    <img
                      src="/assets/v2/icons/delete-grey.svg"
                      alt={"delete"}
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        <div className="user-search">
          <label className="control-label" htmlFor="usersInput">
            Добавить нового
          </label>
          <div className="user-search-form">
            <div className="user-search-typeahead">
              <input
                id="usersInput"
                type="text"
                autoComplete="off"
                placeholder="Введите юзернейм"
                value={searchTerm}
                onFocus={this.onFocus}
                onChange={this.onChange}
              />
              {showUsers &&
                users.length > 0 && (
                  <ul className="user-search-list">
                    {users.map((user, index) => (
                      // eslint-disable-next-line
                      <li
                        ref={node => {
                          this.searchItem = node;
                        }}
                        className="menu"
                        // eslint-disable-next-line
                        key={index}
                        onClick={e => this.selectUser(e, user)}
                      >
                        <UserPreview user={user} />
                      </li>
                    ))}
                  </ul>
                )}
            </div>
            <button
              className="btn-green font-size-small"
              onClick={e => this.submitUser(e)}
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    );
  }
}
