import cn from "classnames";
import Overlay from "../Overlay";
import analiticsTrack from "../../utils/ga";

export default function ToggleMenu({
  toggleMenuLinks,
  toggleMenuisOpen,
  onMenuOverlayClick,
  isUserLoggedIn
}) {
  return (
    <React.Fragment>
      <div
        className={cn("main_header-toggle_menu", {
          open: toggleMenuisOpen
        })}
      >
        {JSON.parse(toggleMenuLinks)
          .filter(l => l.is_visible)
          .map((link, index) => (
            <a
              // eslint-disable-next-line
              key={index}
              href={
                isUserLoggedIn
                  ? link.link
                  : `${link.link}?return_to=${link.link}`
              }
              {...link.data}
              onClick={() =>
                analiticsTrack(
                  link.data["tracker-category"],
                  "click",
                  link.data["tracker-label"],
                  0,
                  false
                )
              }
              className="main_header-toggle_menu-item"
            >
              <span className="main_header-toggle_menu-title">{link.name}</span>
              <span className="main_header-toggle_menu-desc">{link.desc}</span>
            </a>
          ))}
        {/* <a href="/promo" className="main_header-toggle_menu-item last">
          <img src="/assets/v2/icons/promo.svg" alt="promo" />
          <span className="main_header-toggle_menu-title">
            Продвижение анонса
          </span>
        </a> */}
      </div>
      {toggleMenuisOpen && (
        <Overlay withOverflowed={true}>
          <div
            className="main_header-toggle_menu-overlay"
            onClick={onMenuOverlayClick}
          />
        </Overlay>
      )}
    </React.Fragment>
  );
}
