import cn from "classnames";

export default function TextAreaInput({
  value,
  fieldName,
  size,
  rows,
  placeholder,
  errors,
  onChange
}) {
  return (
    <div className="form_primary-group med">
      <div
        className={cn("input", {
          with_error:
            errors && errors[fieldName] && errors[fieldName].length > 0
        })}
      >
        <textarea
          rows={rows}
          value={value}
          maxLength={size}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value, fieldName)}
        />
        {errors && errors[fieldName] && errors[fieldName].length > 0 && (
          <span className="error">{errors[fieldName]}</span>
        )}
      </div>
    </div>
  );
}
