import cn from "classnames";
import PhoneInput from "react-phone-number-input";
import PropTypes from "prop-types";
import ruPhoneLabels from "./PhoneInputLabels";

export default function TextInput({
  type,
  value,
  fieldName,
  size,
  placeholder,
  withLabel,
  labelValue,
  required,
  errors,
  onChange,
  formGroup
}) {
  const formGroupCn = formGroup || "med";
  return (
    <div className={`form_primary-group ${formGroupCn}`}>
      {(type === "tel" && (
        <React.Fragment>
          {withLabel && (
            <label
              htmlFor={fieldName}
              className={cn("control-label", {
                required
              })}
            >
              {labelValue}
            </label>
          )}
          <div
            className={cn("inperview-phone", {
              "inperview-phone-with-error":
                errors && errors[fieldName] && errors[fieldName].length > 0
            })}
          >
            <div>
              <PhoneInput
                country="RU"
                labels={ruPhoneLabels}
                placeholder={placeholder}
                value={value}
                className="string inperview-phone-input"
                onChange={phone => onChange(phone, fieldName)}
              />
            </div>
          </div>
        </React.Fragment>
      )) || (
        <div
          className={cn("input", {
            with_error:
              errors && errors[fieldName] && errors[fieldName].length > 0
          })}
        >
          {withLabel && (
            <label
              htmlFor={fieldName}
              className={cn("control-label", {
                required
              })}
            >
              {placeholder}
            </label>
          )}
          <input
            type="text"
            size={size}
            name={fieldName}
            value={value}
            placeholder={withLabel ? "" : placeholder}
            onChange={event => onChange(event.target.value, fieldName)}
          />
        </div>
      )}
      {errors && errors[fieldName] && errors[fieldName].length > 0 && (
        <span className="error">{errors[fieldName]}</span>
      )}
    </div>
  );
}

TextInput.defaultProps = {
  withLabel: false,
  required: false
};

TextInput.propTypes = {
  withLabel: PropTypes.bool,
  required: PropTypes.bool
};
