import { Suspense, lazy } from "react";

const AutocompleteAnounces = lazy(() => import("./AutocompleteAnounces"));

const AutocompleteAnouncesWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <AutocompleteAnounces {...props} />
    </Suspense>
  );
};

export default AutocompleteAnouncesWrapper;
