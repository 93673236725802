import { Suspense, lazy } from "react";

const PricePicker = lazy(() => import("./PricePicker"));

const PricePickerWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <PricePicker {...props} />
    </Suspense>
  );
};

export default PricePickerWrapper;
