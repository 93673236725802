import { PureComponent } from "react";
import cn from "classnames";
import shortid from "shortid";
import PropTypes from "prop-types";
import axios from "../../utils/axios";
import Modal from "../Modal";

const initialState = {
  showModal: false,
  showContentAnim: false,
  selectedDates: [],
  errors: {}
};

export default class InterviewRegisterModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showContentAnim: false,
      dates: JSON.parse(this.props.dates),
      selectedDates: [],
      showSuccessModal: false,
      errors: {}
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = event => {
    const {
      state: { showModal }
    } = this;
    if (event.keyCode === 27 && showModal) {
      this.handleHide();
    } else if (this.modal && this.modal.contains(event.target)){
      return false;
    }
  };

  onSubmit = () => {
    const {
      state: { dates, selectedDates },
      props: { url }
    } = this;

    axios
      .put(url, {
        dates: dates.length === 1 ? dates.map(date => date.id) : selectedDates
      })
      .then(({ data }) => {
        if (data && data.errors) {
          this.setState({
            errors: data.errors
          });
        } else if (dates.length > 1) {
          this.setState({ showSuccessModal: true });
        }
      })
      .catch(err => {
        console.log("parsing error", err);
      });
  };

  onDateChange = ({ target: { value } }) => {
    const {
      state: { selectedDates }
    } = this;

    let newSelectedDates = [];
    if (selectedDates.includes(value)) {
      newSelectedDates = selectedDates.filter(date => date !== value);
    } else {
      newSelectedDates = [...selectedDates, value];
    }
    this.setState({ selectedDates: newSelectedDates });
  };

  handleHide = event => {
    if (event) {
      if (this.questions && !this.questions.contains(event.target))
        event.preventDefault();
      if (this.modal.contains(event.target)) return;
    }
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.setState({ ...initialState });
    window.location.reload();
  };

  handleShow = event => {
    event.preventDefault();
    const {
      state: { dates }
    } = this;
    if (dates.length === 1) {
      this.setState({ showSuccessModal: true });
      this.onSubmit();
    }
    this.setState({
      showModal: true
    });
    this.timeoutId = setTimeout(() => {
      this.setState({ showContentAnim: true });
    }, 100);
  };

  renderDates = () => {
    const {
      state: { errors, dates, selectedDates }
    } = this;

    const randId = shortid.generate();

    return (
      <div className="form_primary-group med">
        <div className="input">
          <label htmlFor={randId} className="control-label">
            Запись на
            <span className="form_primary-required">*</span>
          </label>
          <div className="collection_wrapper">
            {dates.map(date => (
              <span className="checkbox" key={date.id}>
                <input
                  className="form_primary-checkbox"
                  id={date.id}
                  value={date.id}
                  type="checkbox"
                  checked={selectedDates.includes(String(date.id))}
                  onChange={this.onDateChange}
                />
                <label htmlFor={date.id}>{date.title}</label>
              </span>
            ))}
          </div>
        </div>
        {errors && errors.dates && (
          <span className="error" style={{ bottom: "-15px" }}>
            {errors.dates}
          </span>
        )}
      </div>
    );
  };

  render() {
    const {
      state: { dates, showModal, showContentAnim, showSuccessModal },
      props: { caption, more, btnClass }
    } = this;

    return (
      <React.Fragment>
        <a
          style={{ cursor: "pointer" }}
          className={cn(btnClass, {
            "btn-disabled": showSuccessModal
          })}
          onClick={this.handleShow}
        >
          {showSuccessModal && (
            <img src="/assets/v2/icons/checked.svg" alt="checked" />
          )}
          {caption}
        </a>

        {showModal && (
          <Modal>
            <div
              onClick={this.handleHide}
              className={cn("modal modal-iterview", {
                "in anim": showContentAnim
              })}
              tabIndex="-1"
              style={{ display: "block" }}
            >
              <a href="" className="modal-close">
                <img alt="Close" src="/assets/v2/icons/close.svg" />
              </a>
              <div
                className="modal-dialog"
                ref={node => {
                  this.modal = node;
                }}
              >
                <div className="modal-content">
                  {!showSuccessModal && dates.length > 1 && (
                    <React.Fragment>
                      <h4 className="modal-title">Подача заявки</h4>
                      <div
                        className="questions simple_form form_primary"
                        ref={node => {
                          this.questions = node;
                        }}
                      >
                        {this.renderDates()}

                        <div className="btn btn-green" onClick={this.onSubmit}>
                          Отправить заявку
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {showSuccessModal && (
                    <div className="modal-iterview modal-thanks">
                      <p style={{ fontSize: "22px" }}>
                        Спасибо! Обратите внимание, что вам нужно
                        зарегистрироваться по этой&nbsp;
                        <a
                          style={{ color: "#0ab23b" }}
                          href={more}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ссылке
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

InterviewRegisterModal.defaultProps = {
  caption: "Я пойду",
  more: "",
  dates: "[]",
  url: ""
};

InterviewRegisterModal.propTypes = {
  caption: PropTypes.string,
  more: PropTypes.string,
  dates: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  url: PropTypes.string.isRequired
};
