import { Suspense, lazy } from "react";

const CollectionSelect = lazy(() => import("./CollectionSelect"));

const CollectionSelectWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <CollectionSelect {...props} />
    </Suspense>
  );
};

export default CollectionSelectWrapper;
