import { PureComponent } from "react";
import cn from "classnames";
import axios from "../../utils/axios";
import Modal from "../../components/Modal";

const initialState = {
  showModal: false,
  showContentAnim: false,
  showAll: false
};

export default class ParticipantsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showContentAnim: false,
      showAll: false,
      showFirst: 40,
      showWithoutFirst: this.props.showWithoutFirst
        ? this.props.showWithoutFirst
        : 7,
      showMoreUrl: this.props.showMoreUrl,
      participants: this.props.participants
        ? JSON.parse(this.props.participants)
        : []
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = e => {
    if (e.keyCode === 27) {
      this.handleHide();
    } else {
      return;
    }
    if (this.modal && this.modal.contains(e.target));
  };

  handleHide = e => {
    if (e) {
      if (!this.participants.contains(e.target)) e.preventDefault();
      if (this.modal.contains(e.target)) return;
    }
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.setState({ ...initialState });
  };

  handleShow = e => {
    e.preventDefault();
    this.setState({
      showModal: true
    });
    this.timeoutId = setTimeout(() => {
      this.setState({ showContentAnim: true });
    }, 100);
  };

  showMore = () => {
    const {
      state: { participants, showMoreUrl }
    } = this;

    axios(showMoreUrl).then(({ data }) => {
      this.setState({
        participants: [...participants, data],
        showAll: true
      });
    })
    .catch(err => {
      console.log("parsing failed", err);
    });
  };

  render() {
    const {
      state: {
        participants,
        showAll,
        showFirst,
        showWithoutFirst,
        showModal,
        showContentAnim
      }
    } = this;

    return (
      (participants.length > showWithoutFirst && (
        <span>
          <a
            style={{ cursor: "pointer" }}
            className="platform-participants-show-all"
            onClick={e => this.handleShow(e)}
          >
            {`+${participants.length - showWithoutFirst}`}
          </a>

          {showModal && (
            <Modal>
              <div
                onClick={e => this.handleHide(e)}
                className={cn("modal modal-promo", {
                  "in anim": showContentAnim
                })}
                tabIndex="-1"
                style={{ display: "block" }}
              >
                <a href="" className="modal-close">
                  <img alt="Close" src="/assets/v2/icons/close.svg" />
                </a>
                <div
                  className="modal-dialog"
                  ref={node => {
                    this.modal = node;
                  }}
                >
                  <div className="modal-content">
                    <h5 className="participants-title">
                      Участники ({participants.length})
                    </h5>
                    <div
                      className="participants"
                      ref={node => {
                        this.participants = node;
                      }}
                    >
                      {participants
                        .slice(0, showAll ? participants.length : showFirst)
                        .map((participant, index) => (
                          <a
                            href={participant.link}
                            className="participants-item"
                            // eslint-disable-next-line
                            key={index}
                            index={index}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: participant.userpic
                              }}
                            />
                            <span className="participants-item-name">
                              {participant.name
                                ? participant.name
                                : participant.username}
                            </span>
                          </a>
                        ))}
                    </div>
                    {participants &&
                      participants.length > showFirst &&
                      !showAll && (
                        <span
                          className="link_show_all participants-show-all"
                          onClick={() => this.showMore()}
                          style={{ cursor: "pointer" }}
                        >
                          Показать всех
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="modal-backdrop in" />
            </Modal>
          )}
        </span>
      )) ||
      null
    );
  }
}
