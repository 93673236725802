import { Suspense, lazy } from "react";

const CategoryInput = lazy(() => import("./CategoryInput"));

const CategoryInputWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <CategoryInput {...props} />
    </Suspense>
  );
};

export default CategoryInputWrapper;
