import cn from "classnames";

export default function UserBar({
  isUserLoggedIn,
  feedsItems,
  userBarLinks,
  headerMenuLinks,
  userAvatar,
  userDropdownisOpen,
  toggleSidebarMenu,
  toggleUserDropdown,
  onSearchTriggerClick
}) {
  return (
    <div className="header-right">
      {window.current_user && (window.current_user.is_admin === "true" || window.current_user.is_partner == "true") && (
        <span
          style={{ cursor: "pointer" }}
          className="add_an_event item"
          data-tracker-label="Adding Event Initated"
          onClick={toggleSidebarMenu}
        >
          <img
            alt="Add"
            className="add-an-event-img loaded"
            src="/assets/v2/icons/add.svg"
          />
          <div className="add-an-event-title">Создать анонс</div>
        </span>
      )}

      <span className="search_trigger" onClick={onSearchTriggerClick} />
      {isUserLoggedIn && false &&
        JSON.parse(headerMenuLinks)
          .filter(l => l.name === "Моя лента")
          .map((link, index) => (

            // eslint-disable-next-line
            <div key={index} className="feed-header-link item">
              <a
                href={link.link}
                className={cn({
                  current_category: link.is_active
                })}
              >
                <span>{link.name}</span>
                <span className="header-menu-circle">{feedsItems}</span>
              </a>
            </div>
          ))}
      {isUserLoggedIn && (
        <span className="user_dropdown" onClick={toggleUserDropdown}>
          <span
            className="user_login_in_header"
            dangerouslySetInnerHTML={{
              __html: userAvatar
            }}
          />
          {userDropdownisOpen && (
            <ul className="user_panel user_dropdown-body">
              {JSON.parse(userBarLinks).map((link, index) => (
                // eslint-disable-next-line
                <li key={index}>
                  <a
                    href={link.link}
                    className={link.class}
                    rel={link.rel}
                    role={link.role}
                    data-method={link.method}
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </span>
      )}

      {!isUserLoggedIn && (
        <a href="/users/sign_in" className="item show_login" rel="nofollow">
          Войти
        </a>
      )}
    </div>
  );
}
