export default function analiticsTrack(
  category,
  action,
  label,
  value,
  noninteraction
) {
  if (window.ga && window.analytics)
    window.analytics.googleTrack(
      category,
      action,
      label,
      value,
      noninteraction
    );
}
