import DayPickerInput from "react-day-picker/DayPickerInput";
import cn from "classnames";
import { DateUtils } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import PropTypes from "prop-types";
import {
  WEEKDAYS_SHORT,
  MONTHS,
  WEEKDAYS_LONG,
  FIRST_DAY_OF_WEEK,
  LABELS
} from "../../helpers/DayPickerHelper";

function parseDate(str, format, locale) {
  const splitStr = str.split("/");
  const parsed = dateFnsParse(
    `${splitStr[1]}/${splitStr[0]}/${splitStr[2]}`,
    "MM/DD/YYYY",
    { locale }
  );
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

export default function DayInput({
  locale,
  format,
  value,
  inputName,
  placeholder,
  handleDayChange,
  calendarLeft
}) {
  return (
    <div className="form_primary-group-cell">
      <DayPickerInput
        value={value ? `${formatDate(value, format, "ru")}` : ""}
        formatDate={formatDate}
        format={format}
        parseDate={parseDate}
        onDayChange={handleDayChange || null}
        inputProps={{
          className: cn({ "calendar-left": calendarLeft }),
          name: inputName,
          readOnly: true,
          autoComplete: "off",
          placeholder
        }}
        dayPickerProps={{
          months: MONTHS[locale],
          weekdaysLong: WEEKDAYS_LONG[locale],
          weekdaysShort: WEEKDAYS_SHORT[locale],
          firstDayOfWeek: FIRST_DAY_OF_WEEK[locale],
          labels: LABELS[locale]
        }}
      />
    </div>
  );
}

DayInput.defaultProps = {
  locale: "ru",
  format: "DD/MM/YYYY",
  value: "",
  inputName: "",
  placeholder: "",
  calendarLeft: false
};

DayInput.propTypes = {
  locale: PropTypes.string,
  format: PropTypes.string,
  value: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  calendarLeft: PropTypes.bool
};
