const ru = {
	"ext": "доб.",
	"country": "Страна",
	"AB": "Абхазия",
	"AC": "Остров Вознесения",
	"AD": "Андорра",
	"AE": "Объединенные Арабские Эмираты",
	"AF": "Афганистан",
	"AG": "Антигуа и Барбуда",
	"AI": "Ангилья",
	"AL": "Албания",
	"AM": "Армения",
	"AO": "Ангола",
	"AQ": "Антарктида",
	"AR": "Аргентина",
	"AS": "Американское Самоа",
	"AT": "Австрия",
	"AU": "Австралия",
	"AW": "Аруба",
	"AX": "Эландские острова",
	"AZ": "Азербайджан",
	"BA": "Босния и Герцеговина",
	"BB": "Барбадос",
	"BD": "Бангладеш",
	"BE": "Бельгия",
	"BF": "Буркина-Фасо",
	"BG": "Болгария",
	"BH": "Бахрейн",
	"BI": "Бурунди",
	"BJ": "Бенин",
	"BL": "Сен-Бартельми",
	"BM": "Бермуды",
	"BN": "Бруней-Даруссалам",
	"BO": "Боливия, Многонациональное Государство",
	"BQ": "Бонайре, Саба и Синт-Эстатиус",
	"BR": "Бразилия",
	"BS": "Багамы",
	"BT": "Бутан",
	"BV": "Остров Буве",
	"BW": "Ботсвана",
	"BY": "Беларусь",
	"BZ": "Белиз",
	"CA": "Канада",
	"CC": "Кокосовые (Килинг) острова",
	"CD": "Конго, Демократическая Республика",
	"CF": "Центрально-Африканская Республика",
	"CG": "Конго",
	"CH": "Швейцария",
	"CI": "Кот д'Ивуар",
	"CK": "Острова Кука",
	"CL": "Чили",
	"CM": "Камерун",
	"CN": "Китай",
	"CO": "Колумбия",
	"CR": "Коста-Рика",
	"CU": "Куба",
	"CV": "Кабо-Верде",
	"CW": "Кюрасао",
	"CX": "Остров Рождества",
	"CY": "Кипр",
	"CZ": "Чешская Республика",
	"DE": "Германия",
	"DJ": "Джибути",
	"DK": "Дания",
	"DM": "Доминика",
	"DO": "Доминиканская Республика",
	"DZ": "Алжир",
	"EC": "Эквадор",
	"EE": "Эстония",
	"EG": "Египет",
	"EH": "Западная Сахара",
	"ER": "Эритрея",
	"ES": "Испания",
	"ET": "Эфиопия",
	"FI": "Финляндия",
	"FJ": "Фиджи",
	"FK": "Фолклендские острова (Мальвинские)",
	"FM": "Микронезия, Федеративные Штаты",
	"FO": "Фарерские острова",
	"FR": "Франция",
	"GA": "Габон",
	"GB": "Соединенное Королевство",
	"GD": "Гренада",
	"GE": "Грузия",
	"GF": "Французская Гвиана",
	"GG": "Гернси",
	"GH": "Гана",
	"GI": "Гибралтар",
	"GL": "Гренландия",
	"GM": "Гамбия",
	"GN": "Гвинея",
	"GP": "Гваделупа",
	"GQ": "Экваториальная Гвинея",
	"GR": "Греция",
	"GS": "Южная Джорджия и Южные Сандвичевы острова",
	"GT": "Гватемала",
	"GU": "Гуам",
	"GW": "Гвинея-Бисау",
	"GY": "Гайана",
	"HK": "Гонконг",
	"HM": "Остров Херд и острова Макдональд",
	"HN": "Гондурас",
	"HR": "Хорватия",
	"HT": "Гаити",
	"HU": "Венгрия",
	"ID": "Индонезия",
	"IE": "Ирландия",
	"IL": "Израиль",
	"IM": "Остров Мэн",
	"IN": "Индия",
	"IO": "Британская территория в Индийском океане",
	"IQ": "Ирак",
	"IR": "Иран, Исламская Республика",
	"IS": "Исландия",
	"IT": "Италия",
	"JE": "Джерси",
	"JM": "Ямайка",
	"JO": "Иордания",
	"JP": "Япония",
	"KE": "Кения",
	"KG": "Киргизия",
	"KH": "Камбоджа",
	"KI": "Кирибати",
	"KM": "Коморы",
	"KN": "Сент-Китс и Невис",
	"KP": "Корея, Народно-Демократическая Республика",
	"KR": "Корея, Республика",
	"KW": "Кувейт",
	"KY": "Острова Кайман",
	"KZ": "Казахстан",
	"LA": "Лаос",
	"LB": "Ливан",
	"LC": "Сент-Люсия",
	"LI": "Лихтенштейн",
	"LK": "Шри-Ланка",
	"LR": "Либерия",
	"LS": "Лесото",
	"LT": "Литва",
	"LU": "Люксембург",
	"LV": "Латвия",
	"LY": "Ливийская Арабская Джамахирия",
	"MA": "Марокко",
	"MC": "Монако",
	"MD": "Молдова, Республика",
	"ME": "Черногория",
	"MF": "Сен-Мартен",
	"MG": "Мадагаскар",
	"MH": "Маршалловы острова",
	"MK": "Республика Македония",
	"ML": "Мали",
	"MM": "Мьянма",
	"MN": "Монголия",
	"MO": "Макао",
	"MP": "Северные Марианские острова",
	"MQ": "Мартиника",
	"MR": "Мавритания",
	"MS": "Монтсеррат",
	"MT": "Мальта",
	"MU": "Маврикий",
	"MV": "Мальдивы",
	"MW": "Малави",
	"MX": "Мексика",
	"MY": "Малайзия",
	"MZ": "Мозамбик",
	"NA": "Намибия",
	"NC": "Новая Каледония",
	"NE": "Нигер",
	"NF": "Остров Норфолк",
	"NG": "Нигерия",
	"NI": "Никарагуа",
	"NL": "Нидерланды",
	"NO": "Норвегия",
	"NP": "Непал",
	"NR": "Науру",
	"NU": "Ниуэ",
	"NZ": "Новая Зеландия",
	"OM": "Оман",
	"OS": "Южная Осетия",
	"PA": "Панама",
	"PE": "Перу",
	"PF": "Французская Полинезия",
	"PG": "Папуа-Новая Гвинея",
	"PH": "Филиппины",
	"PK": "Пакистан",
	"PL": "Польша",
	"PM": "Сент-Пьер и Микелон",
	"PN": "Питкерн",
	"PR": "Пуэрто-Рико",
	"PS": "Палестинская территория, оккупированная",
	"PT": "Португалия",
	"PW": "Палау",
	"PY": "Парагвай",
	"QA": "Катар",
	"RE": "Реюньон",
	"RO": "Румыния",
	"RS": "Сербия",
	"RU": "Россия",
	"RW": "Руанда",
	"SA": "Саудовская Аравия",
	"SB": "Соломоновы острова",
	"SC": "Сейшелы",
	"SD": "Судан",
	"SE": "Швеция",
	"SG": "Сингапур",
	"SH": "Святая Елена, Остров вознесения, Тристан-да-Кунья",
	"SI": "Словения",
	"SJ": "Шпицберген и Ян Майен",
	"SK": "Словакия",
	"SL": "Сьерра-Леоне",
	"SM": "Сан-Марино",
	"SN": "Сенегал",
	"SO": "Сомали",
	"SR": "Суринам",
	"SS": "Южный Судан",
	"ST": "Сан-Томе и Принсипи",
	"SV": "Эль-Сальвадор",
	"SX": "Синт-Мартен",
	"SY": "Сирийская Арабская Республика",
	"SZ": "Свазиленд",
	"TA": "Тристан-да-Кунья",
	"TC": "Острова Теркс и Кайкос",
	"TD": "Чад",
	"TF": "Французские Южные территории",
	"TG": "Того",
	"TH": "Таиланд",
	"TJ": "Таджикистан",
	"TK": "Токелау",
	"TL": "Тимор-Лесте",
	"TM": "Туркмения",
	"TN": "Тунис",
	"TO": "Тонга",
	"TR": "Турция",
	"TT": "Тринидад и Тобаго",
	"TV": "Тувалу",
	"TW": "Тайвань (Китай)",
	"TZ": "Танзания, Объединенная Республика",
	"UA": "Украина",
	"UG": "Уганда",
	"UM": "Малые Тихоокеанские отдаленные острова Соединенных Штатов",
	"US": "Соединенные Штаты",
	"UY": "Уругвай",
	"UZ": "Узбекистан",
	"VA": "Папский Престол (Государство &mdash; город Ватикан)",
	"VC": "Сент-Винсент и Гренадины",
	"VE": "Венесуэла Боливарианская Республика",
	"VG": "Виргинские острова, Британские",
	"VI": "Виргинские острова, США",
	"VN": "Вьетнам",
	"VU": "Вануату",
	"WF": "Уоллис и Футуна",
	"WS": "Самоа",
	"XK": "Косово",
	"YE": "Йемен",
	"YT": "Майотта",
	"ZA": "Южная Африка",
	"ZM": "Замбия",
	"ZW": "Зимбабве",
	"ZZ": "Международный"
}

export default ru;