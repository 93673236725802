import { Suspense, lazy } from "react";

const Dropzone = lazy(() => import("./Dropzone"));

const DropzoneWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <Dropzone {...props} />
    </Suspense>
  );
};

export default DropzoneWrapper;
