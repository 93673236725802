import { PureComponent } from "react";
import cn from "classnames";
import axios from "../../utils/axios";
import Modal from "../../components/Modal";

const initialState = {
  showModal: false
};

export default class DisclaimerModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.showOnCreate === 'true',
      text: this.props.text,
      showOnCreate: this.props.showOnCreate === 'true'
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = e => {
    if (e.keyCode === 27) {
      this.handleHide();
    } else {
      return;
    }
    if (this.modal && this.modal.contains(e.target));
  };

  handleHide = e => {
    if (e) {
      if (this.modal.contains(e.target)) return;
    }
    this.setState({ ...initialState });
  };

  handleShow = e => {
    e.preventDefault();
    this.setState({
      showModal: true
    });
  };

  render() {
    const {
      state: {
        text,
        showModal,
        showOnCreate
      }
    } = this;

    return (
      <span>
        {!showOnCreate && (
          <a
            id='disclaimer_button'
            onClick={e => this.handleShow(e)}
          >
          </a>
        )}

        {showModal && (
          <Modal>
            <div
              onClick={e => this.handleHide(e)}
              className="modal modal-promo in anim"
              tabIndex="-1"
              style={{ display: "block" }}
            >
              <a href="#" className="modal-close">
                <img alt="Close" src="/assets/v2/icons/close.svg" />
              </a>
              <div
                className="modal-dialog"
                ref={node => {
                  this.modal = node;
                }}
              >
                <div className="modal-content">
                  <div >
                    <p>
                      У вас уже был создан ранее личный кабинет на TimePad.
                      <br/>
                      В рамках интеграции мы просто добавили недостающие события или курсы с "Теорий и Практик" в ваш эккаунт на TimePad.
                      <br/>
                      Перейдите в свой личный кабинет там и просто активируйте их.
                      <br/>
                      <br/>
                      <a href="https://timepad.ru/" className="btn-small orange">
                        Перейти на Таймпад
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" />
          </Modal>
        )}
      </span>
    );
  }
}