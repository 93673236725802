import { PureComponent } from "react";
import cn from "classnames";
import shortid from "shortid";
import PropTypes from "prop-types";
import axios from "../../utils/axios";
import Modal from "../Modal";
import TextInput from "../FormInputs/TextInput";
import TextAreaInput from "../FormInputs/TextAreaInput";

const initialState = {
  showModal: false,
  showContentAnim: false,
  showSuccessModal: false,
  answerVariants: [],
  selectedDates: [],
  errors: {}
};

export default class InterviewModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showContentAnim: false,
      showSuccessModal: false,
      questions: JSON.parse(this.props.questions),
      dates: JSON.parse(this.props.dates),
      selectedDates: [],
      answerVariants: [],
      results: [],
      errors: {}
    };
  }

  componentDidMount() {
    const {
      state: { questions },
      props: { answerVariants }
    } = this;
    const newResults = [];
    const newAnswerVariants = [];
    JSON.parse(answerVariants).map(a =>
      a.length > 0 ? newAnswerVariants.push(...a) : a
    );
    questions.map(question =>
      newResults.push({
        result: question.answer_type === "multi_select" ? [""] : "",
        id: question.id
      })
    );
    Object.assign(initialState, {
      questions,
      results: newResults,
      answerVariants: newAnswerVariants
    });

    this.setState({
      results: newResults,
      answerVariants: newAnswerVariants
    });
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = e => {
    if (e.keyCode === 27) {
      this.handleHide();
    } else {
      return;
    }
    if (this.modal && this.modal.contains(e.target));
  };

  onSubmit = () => {
    const {
      state: { results, selectedDates },
      props: { url }
    } = this;

    const questionsAttributes = {};

    results.map((result, index) =>
      Object.assign(questionsAttributes, { [index]: result })
    );

    axios
      .put(url, {
        interview_interview: {
          questions_attributes: questionsAttributes
        },
        dates: selectedDates
      })
      .then(({ data }) => {
        if (data && data.errors) {
          this.setState({
            errors: data.errors
          });
        } else {
          this.setState({
            showSuccessModal: true
          });
          let id = parseInt(this.props.resourceId);
          let idList = [154559, 154582, 154601, 154603, 154570];
          if(window.ga && idList.some(item => item === id)){
            ga('send', 'event', 'butreg', 'beelinevebinar');
            if (yaCounter32282879) {
              yaCounter32282879.reachGoal('regbeelinevebinar');
              if(id === 154582) {
                yaCounter32282879.reachGoal('submitvebinarcommunication');
              }
              if(id === 154603) {
                yaCounter32282879.reachGoal('submitvebinarinstrumentyliderov');
              }
              if(id === 154570) {
                yaCounter32282879.reachGoal('submitvebinarcriticalmind');
              }
              if(id === 154601) {
                yaCounter32282879.reachGoal('submitvebinarsprint');
              }
            }
          }

          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      })
      .catch(err => {
        console.log("parsing error", err);
      });
  };

  onFieldChange = (value, questionId, answerType) => {
    const {
      state: { results }
    } = this;

    if (answerType === "multi_select") {
      const resultArray = results.find(r => r.id === questionId).result;
      if (resultArray.includes(value)) {
        const filteredResult = resultArray.filter(r => r !== value);
        this.setState({
          results: results.map(r =>
            r.id === questionId
              ? Object.assign({}, r, { result: filteredResult })
              : r
          )
        });
      } else {
        this.setState({
          results: results.map(r =>
            r.id === questionId
              ? Object.assign({}, r, {
                  result: [...resultArray, value]
                })
              : r
          )
        });
      }
    } else {
      this.setState({
        results: results.map(r =>
          r.id === questionId ? Object.assign({}, r, { result: value }) : r
        )
      });
    }
  };

  onDateChange = ({ target: { value } }) => {
    const {
      state: { selectedDates }
    } = this;

    let newSelectedDates = [];
    if (selectedDates.includes(value)) {
      newSelectedDates = selectedDates.filter(date => date !== value);
    } else {
      newSelectedDates = [...selectedDates, value];
    }
    this.setState({ selectedDates: newSelectedDates });
  };

  handleHide = e => {
    const {
      state: { showSuccessModal }
    } = this;
    if (e) {
      if (!showSuccessModal && !this.questions.contains(e.target))
        e.preventDefault();
      if (this.modal.contains(e.target)) return;
    }
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.setState({ ...initialState });
  };

  handleShow = e => {
    e.preventDefault();
    this.setState({
      showModal: true
    });
    this.timeoutId = setTimeout(() => {
      this.setState({ showContentAnim: true });
    }, 100);
  };

  renderQuestion = question => {
    const {
      state: { answerVariants, results, errors }
    } = this;
    switch (question.answer_type) {
      case "text":
        return (
          <div
            className={cn("string", {
              with_error: errors && errors[question.id]
            })}
          >
            <label htmlFor={question.id} className="control-label">
              {question.title}
              &nbsp;
              {question.required && (
                <span className="form_primary-required">*</span>
              )}
            </label>
            <TextInput
              placeholder=""
              value={results.find(r => r.id === question.id).result || ""}
              formGroup="big"
              onChange={value => this.onFieldChange(value, question.id, "")}
            />
            {errors && errors[question.id] && (
              <span className="error">{errors[question.id]}</span>
            )}
          </div>
        );
      case "comment":
        return (
          <div
            className={cn("string", {
              with_error: errors && errors[question.id]
            })}
          >
            <label htmlFor={question.id} className="control-label">
              {question.title}
              &nbsp;
              {question.required && (
                <span className="form_primary-required">*</span>
              )}
            </label>
            <TextAreaInput
              rows={2}
              placeholder=""
              value={results.find(r => r.id === question.id).result || ""}
              onChange={value => this.onFieldChange(value, question.id, "")}
            />
            {errors && errors[question.id] && (
              <span className="error" style={{ bottom: "6px" }}>
                {errors[question.id]}
              </span>
            )}
          </div>
        );
      case "single_select":
        return (
          <div className="form_primary-group med">
            <div className="input">
              <label htmlFor={question.id} className="control-label">
                {question.title}
                &nbsp;
                {question.required && (
                  <span className="form_primary-required">*</span>
                )}
              </label>
              <div className="collection_wrapper">
                {answerVariants
                  .filter(a => a.question_id === question.id)
                  .map(av => (
                    <span className="radio" key={av.id}>
                      <input
                        className="form_primary-checkbox"
                        id={av.id}
                        value={av.id}
                        checked={
                          results.find(r => r.id === question.id).result ===
                          String(av.id)
                        }
                        type="radio"
                        onChange={value =>
                          this.onFieldChange(value, question.id, "")
                        }
                      />
                      <label htmlFor={av.id}>{av.title}</label>
                    </span>
                  ))}
              </div>
              {errors && errors[question.id] && (
                <span className="error" style={{ bottom: "-24px" }}>
                  {errors[question.id]}
                </span>
              )}
            </div>
          </div>
        );
      case "multi_select":
        return (
          <div className="form_primary-group med">
            <div className="input">
              <label htmlFor={question.id} className="control-label">
                {question.title}
                &nbsp;
                {question.required && (
                  <span className="form_primary-required">*</span>
                )}
              </label>
              <div className="collection_wrapper">
                {answerVariants
                  .filter(a => a.question_id === question.id)
                  .map(av => (
                    <span className="checkbox" key={av.id}>
                      <input
                        className="form_primary-checkbox"
                        id={av.id}
                        value={av.id}
                        type="checkbox"
                        checked={results
                          .find(r => r.id === question.id)
                          .result.includes(String(av.id))}
                        onChange={value =>
                          this.onFieldChange(value, question.id, "multi_select")
                        }
                      />
                      <label htmlFor={av.id}>{av.title}</label>
                    </span>
                  ))}
              </div>
              {errors && errors[question.id] && (
                <span className="error" style={{ bottom: "-16px" }}>
                  {errors[question.id]}
                </span>
              )}
            </div>
          </div>
        );
      case "phone":
        return (
          <div className="string">
            <label htmlFor={question.id} className="control-label">
              {question.title}
              &nbsp;
              {question.required && (
                <span className="form_primary-required">*</span>
              )}
            </label>
            <TextInput
              type="tel"
              fieldName="phone"
              placeholder=""
              formGroup="big"
              errors={errors && errors[question.id] ? { phone: " " } : null}
              value={results.find(r => r.id === question.id).result || ""}
              onChange={value => this.onFieldChange(value, question.id, "")}
            />
            {errors && errors[question.id] && (
              <span className="error">{errors[question.id]}</span>
            )}
          </div>
        );
      default:
        return "";
    }
  };

  renderDates = () => {
    const {
      state: { errors, dates, selectedDates }
    } = this;

    const randId = shortid.generate();
    return (
      (dates.length > 1 && (
        <div className="form_primary-group med">
          <div className="input">
            <label htmlFor={randId} className="control-label">
              Запись на
              <span className="form_primary-required">*</span>
            </label>
            <div className="collection_wrapper">
              {dates.map(date => (
                <span className="checkbox" key={date.id}>
                  <input
                    className="form_primary-checkbox"
                    id={date.id}
                    value={date.id}
                    type="checkbox"
                    checked={selectedDates.includes(String(date.id))}
                    onChange={this.onDateChange}
                  />
                  <label htmlFor={date.id}>{date.title}</label>
                </span>
              ))}
            </div>
          </div>
          {errors && errors.dates && (
            <span className="error" style={{ bottom: "-15px" }}>
              {errors.dates}
            </span>
          )}
        </div>
      )) ||
      null
    );
  };

  render() {
    const {
      state: { questions, showModal, showContentAnim, showSuccessModal },
      props: { caption, btnClass }
    } = this;

    return (
      <React.Fragment>
        <a
          style={{ cursor: "pointer" }}
          className={btnClass}
          onClick={this.handleShow}
        >
          {caption}
        </a>

        {showModal && (
          <Modal>
            <div
              onClick={this.handleHide}
              className={cn("modal modal-iterview", {
                "in anim": showContentAnim
              })}
              tabIndex="-1"
              style={{ display: "block" }}
            >
              <a href="" className="modal-close">
                <img alt="Close" src="/assets/v2/icons/close.svg" />
              </a>
              <div
                className="modal-dialog"
                ref={node => {
                  this.modal = node;
                }}
              >
                <div className="modal-content">
                  {!showSuccessModal && (
                    <React.Fragment>
                      <h4 className="modal-title">Подача заявки</h4>
                      <p className="modal-description">
                        Чтобы зарегистрироваться на этот анонс, заполните
                        анкету. Информация о вас будет доступна только
                        организаторам.
                      </p>
                      <div
                        className="questions simple_form form_primary"
                        ref={node => {
                          this.questions = node;
                        }}
                      >
                        {this.renderDates()}
                        {questions.map(question => (
                          <div
                            className="interview-field"
                            key={question.priority}
                          >
                            {this.renderQuestion(question)}
                          </div>
                        ))}
                        <div className="btn btn-green" onClick={this.onSubmit}>
                          Отправить заявку
                        </div>
                        <span className="modal-hint">
                          Нажимая на кнопку, вы даете согласие на обработку
                          персональных данных и соглашаетесь c политикой
                          конфиденциальности
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                  {showSuccessModal && (
                    <div className="modal-iterview modal-thanks">
                      <img src="/assets/v2/icons/succsess.svg" alt="succsess" />
                      <h1 className="modal-thanks-text">
                        Спасибо, ваша заявка отправлена
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

InterviewModal.defaultProps = {
  caption: "Регистрация",
  dates: "[]"
};

InterviewModal.propTypes = {
  caption: PropTypes.string,
  resourceId: PropTypes.number,
  dates: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  questions: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  answerVariants: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  url: PropTypes.string.isRequired
};
