import { PureComponent } from "react";
import DropzoneComponent from "react-dropzone-component";
import shortid from "shortid";
import axios from "../../utils/axios";
import ImageuploadInputTabs from "../../components/ImageuploadInput/tabs";

export default class ImageuploadInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images ? JSON.parse(this.props.images) : [],
      fields: this.props.fields ? JSON.parse(this.props.fields) : [],
      additionalFields: this.props.additionalFields
        ? JSON.parse(this.props.additionalFields)
        : []
    };
  }

  onAddedImage = file => {
    const {
      state: { images },
      props: { isTemp, inputController, inputName, onAddedImage }
    } = this;
    const randId = shortid.generate();
    this.setState({ images: [...images, { uploaded: false, randId }] });
    const formData = new FormData();
    formData.append(`${inputName}[image]`, file);
    formData.append("temp", isTemp);
    axios({
      method: "post",
      url: `/${inputController}.json`,
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    })
      .then(({ data }) => {
        if (onAddedImage) onAddedImage(data);
        this.setState({
          images: this.state.images.map(item =>
            item.randId === randId
              ? Object.assign({}, item, { ...data, uploaded: true })
              : item
          )
        });
      })
      .catch(err => {
        console.log("parsing failed", err);
      });
  };

  triggerUploadDialog = () => {
    this.dropzoneNode.dropzone.element.click();
  };

  deleteImage = index => {
    const {
      state: { images },
      props: { isTemp, inputController, onDeleteImage }
    } = this;
    const image = images[index];
    image.deleting = true;
    axios
      .delete(`/${inputController}/${image.id}`, { data: { temp: isTemp } })
      .then(({ data }) => {
        if (data && data.destroy) {
          if (onDeleteImage) onDeleteImage();
          this.setState({
            images: images.filter(item => item.id !== data.destroy.id)
          });
        }
      })
      .catch(err => {
        console.log("parsing failed", err);
      });
  };

  render() {
    const {
      state: { images, fields, additionalFields },
      props: { inputName, inputController, formName, isMultiple }
    } = this;

    const componentConfig = { postUrl: "no-url" };
    const djsConfig = {
      autoProcessQueue: false,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      paramName: `${inputName}[image]`,
      enqueueForUpload: true,
      uploadMultiple: isMultiple || false,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          ? document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content")
          : ""
      }
    };
    const eventHandlers = {
      addedfile: file => this.onAddedImage(file)
    };

    return (
      <div>
        <ul>
          {images.map((image, index) => (
            // eslint-disable-next-line
            <li className="imageupload_image_wrapper" key={index}>
              {(!image.uploaded || image.deleting) && (
                <div className="spiner" />
              )}
              {image && image.src && (
                <div
                  className="bg"
                  style={{ backgroundImage: `url(${image.src})` }}
                />
              )}
              <div className="imageupload_inputs">
                <ImageuploadInputTabs
                  image={image}
                  fields={fields}
                  additionalFields={additionalFields}
                  inputName={inputName}
                  inputController={inputController}
                  formName={formName}
                  isMultiple={isMultiple}
                />
              </div>
              {image.uploaded && !image.deleting && (
                <div
                  className="delete"
                  onClick={() => this.deleteImage(index)}
                />
              )}
            </li>
          ))}
          <DropzoneComponent
            className="dropzone-hidden"
            ref={node => {
              this.dropzoneNode = node;
            }}
            eventHandlers={eventHandlers}
            config={componentConfig}
            djsConfig={djsConfig}
          />
        </ul>
        {(isMultiple || !images.length) && (
          <div
            className="imageupload_add"
            onClick={() => this.triggerUploadDialog()}
          >
            Выбрать
          </div>
        )}
      </div>
    );
  }
}
