export function getTitle(modelName) {
  switch (modelName) {
    case "seminars":
      return "Лекции";
    case "courses":
      return "Курсы";
    case "grants":
      return "Гранты";
    case "posts":
      return "Посты";
    case "videos":
      return "Видео";
    default:
      return "";
  }
}

export function getFirstActiveTAbIndex(
  seminarsCount,
  coursesCount,
  grantsCount,
  postsCount,
  videosCount
) {
  if (seminarsCount > 0) {
    return 0;
  }
  if (coursesCount > 0) {
    return 1;
  }
  if (grantsCount > 0) {
    return 2;
  }
  if (postsCount > 0) {
    return 3;
  }
  if (videosCount > 0) {
    return 4;
  }

  return 0;
}

export function getTAbIndexByModelName(modelName) {
  switch (modelName) {
    case "seminars":
      return 0;
    case "courses":
      return 1;
    case "grants":
      return 2;
    case "posts":
      return 3;
    case "videos":
      return 4;
    default:
      return "";
  }
}
