import { PureComponent } from "react";
import DropzoneComponent from "react-dropzone-component";
import shortid from "shortid";
import PropTypes from "prop-types";
import axios from "../../utils/axios";

export default class MaterialsUploader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images ? JSON.parse(this.props.images) : [],
      fields: this.props.fields ? JSON.parse(this.props.fields) : []
    };
  }

  onAddedImage = file => {
    const {
      state: { images },
      props: { inputController, inputName, onAddedImage }
    } = this;
    const randId = shortid.generate();
    this.setState({ images: [...images, { uploaded: false, randId }] });
    const formData = new FormData();
    formData.append(`${inputName}[image]`, file);
    axios({
      method: "post",
      url: `/${inputController}.json`,
      data: formData
    })
      .then(({ data }) => {
        if (onAddedImage) onAddedImage(data);
        this.setState({
          images: this.state.images.map(item =>
            item.randId === randId
              ? Object.assign({}, item, {
                  ...data,
                  uploaded: true,
                  src: data.image.url
                })
              : item
          )
        });
      })
      .catch(err => {
        console.log("parsing failed", err);
      });
  };

  triggerUploadDialog = () => {
    this.dropzoneNode.dropzone.element.click();
  };

  deleteImage = index => {
    const {
      state: { images },
      props: { inputController, onDeleteImage }
    } = this;
    const image = images[index];
    image.deleting = true;
    axios.delete(`/${inputController}/${image.id}`).then(({ data }) => {
      if (data && data.destroy) {
        if (onDeleteImage) onDeleteImage();
        this.setState({
          images: images.filter(item => item.id !== data.destroy.id)
        });
      }
    });
  };

  render() {
    const {
      state: { images, fields },
      props: { inputName }
    } = this;

    const componentConfig = { postUrl: "no-url" };
    const djsConfig = {
      autoProcessQueue: false,
      acceptedFiles: "image/jpeg,image/png,image/gif",
      paramName: `${inputName}[image]`,
      enqueueForUpload: true,
      uploadMultiple: true,
      maxFilesize: 2,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          ? document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content")
          : ""
      }
    };
    const eventHandlers = {
      addedfile: file => this.onAddedImage(file)
    };

    return (
      <div>
        <ul>
          {images.map((image, index) => (
            // eslint-disable-next-line
            <li className="imageupload_image_wrapper" key={index}>
              {(!image.uploaded || image.deleting) && (
                <div className="spiner" />
              )}
              {image && image.src && (
                <div
                  className="bg"
                  style={{ backgroundImage: `url(${image.src})` }}
                />
              )}
              <div className="imageupload_inputs">
                <div className="imageupload-tabs">
                  <div className="imageupload-tab-main">
                    <input
                      id="images_id"
                      name={`${inputName}[][id]`}
                      type="hidden"
                      defaultValue={image.id}
                    />
                    {image.uploaded && (
                      <div>
                        <ul>
                          {fields.map((field, i) => (
                            // eslint-disable-next-line
                            <li className="input" key={i}>
                              <label htmlFor={`${inputName}_${image.id}`}>
                                {field.label}
                              </label>
                              <input
                                className="string"
                                id={`${inputName}_${image.id}`}
                                name={`${inputName}[][${field.name}]`}
                                defaultValue={image[field.name] || ""}
                                autoComplete="off"
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {image.uploaded && !image.deleting && (
                <div
                  className="delete"
                  onClick={() => this.deleteImage(index)}
                />
              )}
            </li>
          ))}
          <DropzoneComponent
            className="dropzone-hidden"
            ref={node => {
              this.dropzoneNode = node;
            }}
            eventHandlers={eventHandlers}
            config={componentConfig}
            djsConfig={djsConfig}
          />
        </ul>
        {images.length < 6 && (
          <div
            className="imageupload_add"
            onClick={() => this.triggerUploadDialog()}
          >
            Выбрать
          </div>
        )}
      </div>
    );
  }
}

MaterialsUploader.propTypes = {
  images: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  fields: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};
