import { Suspense, lazy } from "react";

const OrganizationFeedbackModal = lazy(() =>
  import("./OrganizationFeedbackModal")
);

const OrganizationFeedbackModalWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <OrganizationFeedbackModal {...props} />
    </Suspense>
  );
};

export default OrganizationFeedbackModalWrapper;
