import { Suspense, lazy } from "react";

const GrantDates = lazy(() => import("./GrantDates"));

const GrantDatesWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <GrantDates {...props} />
    </Suspense>
  );
};

export default GrantDatesWrapper;
