import { PureComponent } from "react";
import cn from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "../../utils/axios";
import Modal from "../../components/Modal";
import {
  getTitle,
  getFirstActiveTAbIndex,
  getTAbIndexByModelName
} from "../../helpers/RelatedAnnouncesHelper";
import TagsAutocomplete from "../../components/TagsAutocomplete";
import RelatedAnnouncesItem from "./Item";

export default class RelatedAnnounces extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputName: this.props.inputName,
      showModal: false,
      selectedTag: null,
      selectedSlot: null,
      onlyPosts: Boolean(this.props.onlyPosts) || false,
      slotsCount: parseInt(this.props.slotsCount, 10) || 3,
      tags: this.props.parsedTags
        ? this.props.parsedTags
        : [...JSON.parse(this.props.tags)],
      items: {
        seminars: { items: [], count: 0 },
        courses: { items: [], count: 0 },
        grants: { items: [], count: 0 },
        posts: { items: [], count: 0 },
        videos: { items: [], count: 0 }
      },
      tabs: [],
      activeTAbIndex: 0,
      postId: this.props.postId ? JSON.parse(this.props.postId) : null,
      relatedAnnounces: [],
      showAutocomplete: Boolean(this.props.showAutocomplete) || false,
      showCode: Boolean(this.props.showCode) || false
    };
  }

  componentWillMount() {
    const {
      props: { relatedAnnounces, tags, parsedTags }
    } = this;
    if (JSON.parse(relatedAnnounces).length > 0) {
      // eslint-disable-next-line
      const actualTags = parsedTags ? parsedTags : [...JSON.parse(tags)];
      const newRelatedAnnounces = [];
      JSON.parse(relatedAnnounces).map(item =>
        newRelatedAnnounces.push({
          selectedSlot: item.position,
          tagId: item.tag_id,
          tagName:
            actualTags.filter(tag => tag.id === item.tag_id).length > 0
              ? actualTags.filter(tag => tag.id === item.tag_id)[0].name
              : "",
          taggableId: item.taggable_id,
          taggableType: item.taggable_type
        })
      );
      this.setState({
        actualTags,
        relatedAnnounces: newRelatedAnnounces.filter(
          item => item.tagName !== ""
        )
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ tags: nextProps.parsedTags });
  }

  handleShow = (e, selectedTag, selectedSlot) => {
    const {
      state: { onlyPosts }
    } = this;
    e.preventDefault();
    if (selectedTag) {
      axios(
        `/tags/${selectedTag.id}?choose=1&tag_name=${selectedTag.name}`
      ).then(({ data }) => {
        if (data) {
          let items = {};
          if (onlyPosts) {
            items = {
              posts: { items: data.posts, count: data.posts_count }
            };
          } else {
            items = {
              seminars: {
                items: data.seminars,
                count: data.seminars_count
              },
              courses: {
                items: data.courses,
                count: data.courses_count
              },
              grants: {
                items: data.grants,
                count: data.grants_count
              },
              posts: { items: data.posts, count: data.posts_count },
              videos: { items: data.videos, count: data.videos_count }
            };
          }
          this.setState({
            showModal: true,
            selectedTag,
            items,
            selectedSlot,
            tabs: Object.keys(items).filter(
              item => items[item].items.length !== 0
            ),
            activeTAbIndex: onlyPosts
              ? 3
              : getFirstActiveTAbIndex(
                  data.seminars_count,
                  data.courses_count,
                  data.grants_count,
                  data.posts_count,
                  data.videos_count
                )
          });
        }
      });
    }
  };

  handleClickTab = modelName => {
    const activeTAbIndex = getTAbIndexByModelName(modelName);
    this.setState({ activeTAbIndex });
  };

  handleHide = e => {
    e.preventDefault();
    if (this.modal.contains(e.target)) return;
    this.setState({
      showModal: false,
      selectedTag: null,
      selectedSlot: null,
      items: {
        seminars: { items: [], count: 0 },
        courses: { items: [], count: 0 },
        grants: { items: [], count: 0 },
        posts: { items: [], count: 0 },
        videos: { items: [], count: 0 }
      },
      tabs: [],
      activeTAbIndex: 0
    });
  };

  handleSelectRelated = (
    e,
    selectedSlot,
    selectedTag,
    taggableId,
    taggableType
  ) => {
    e.preventDefault();
    const {
      state: { relatedAnnounces }
    } = this;
    const newItem = {
      selectedSlot,
      tagId: selectedTag.id,
      tagName: selectedTag.name,
      taggableId,
      taggableType
    };
    this.setState({
      relatedAnnounces: [...relatedAnnounces, newItem],
      showModal: false,
      selectedTag: null,
      selectedSlot: null,
      items: {
        seminars: { items: [], count: 0 },
        courses: { items: [], count: 0 },
        grants: { items: [], count: 0 },
        posts: { items: [], count: 0 },
        videos: { items: [], count: 0 }
      },
      tabs: [],
      activeTAbIndex: 0
    });
  };

  isRelatedAnnouncesExist = (tag, slotId) => {
    const {
      state: { relatedAnnounces }
    } = this;
    return (
      relatedAnnounces.filter(
        item =>
          item.selectedSlot === slotId &&
          item.tagId === tag.id &&
          item.tagName === tag.name
      ).length > 0
    );
  };

  findRelatedAnnounce = (tag, slotId) => {
    const {
      state: { relatedAnnounces }
    } = this;
    const findRelatedAnnounces = relatedAnnounces.filter(
      item =>
        item.selectedSlot === slotId &&
        item.tagId === tag.id &&
        item.tagName === tag.name
    );
    return findRelatedAnnounces[0] || [];
  };

  handleDelete = (e, tag, slotId, taggableId) => {
    e.preventDefault();
    const {
      state: { relatedAnnounces }
    } = this;
    const filteredRelatedAnnounces = relatedAnnounces.filter(item => {
      if (
        item.selectedSlot === slotId &&
        item.tagName === tag.name &&
        item.tagId === tag.id &&
        item.taggableId === taggableId
      ) {
        return false;
      }
      return true;
    });
    this.setState({ relatedAnnounces: filteredRelatedAnnounces });
  };

  handleTagsChange = tags => {
    this.setState({ tags });
  };

  render() {
    const {
      state: {
        tags,
        showModal,
        selectedTag,
        items,
        tabs,
        activeTAbIndex,
        postId,
        selectedSlot,
        slotsCount,
        inputName,
        showAutocomplete,
        showCode
      },
      props: {
        paramName
      }
    } = this;

    const TableItem = ({
      id,
      isExist,
      handleShow,
      handleDelete,
      findRelatedAnnounce,
      slotId,
      tag
    }) => (
      <td key={slotId}>
        {isExist ? (
          <div>
            {findRelatedAnnounce.taggableId}
            <button
              onClick={e =>
                handleDelete(e, tag, slotId, findRelatedAnnounce.taggableId)
              }
            >
              Удалить
            </button>
            <input
              type="hidden"
              name={`${inputName}[${id}][taggable_id]`}
              value={findRelatedAnnounce.taggableId}
              readOnly
            />
            <input
              type="hidden"
              name={`${inputName}[${id}][taggable_type]`}
              value={findRelatedAnnounce.taggableType}
              readOnly
            />
            <input
              type="hidden"
              name={`${inputName}[${id}][position]`}
              value={slotId}
              readOnly
            />
            <input
              type="hidden"
              name={`${inputName}[${id}][tag_id]`}
              value={tag.id}
              readOnly
            />
          </div>
        ) : (
          <button onClick={e => handleShow(e, tag, slotId)}>Выбор…</button>
        )}
      </td>
    );

    return (
      <div className="input">
        <h2 className="header_h2"> {this.props.title} </h2>
        {showAutocomplete && (
          <div className="input">
            <TagsAutocomplete
              handleTagsChange={this.handleTagsChange}
              init={this.props.tags}
              name="media_post[tag_list]"
              type="posts"
            />
          </div>
        )}
        <input
          type="hidden"
          name={`${inputName}[-1][fake]`}
          value=""
          readOnly
        />
        <table
          className="table_admin table_padded"
          cellPadding="10"
          style={{ width: "100%" }}
        >
          <tbody>
            <tr>
              <td> Тег </td>
              {[...Array(slotsCount)].map((value, index) => (
                // eslint-disable-next-line
                <td key={index}> Анонс {index} (id) </td>
              ))}
              {showCode && <td> Код для вставки </td>}
            </tr>
            {tags.map((tag, index) => (
              // eslint-disable-next-line
              <tr key={index}>
                <td>{tag.name}</td>
                {[...Array(slotsCount)].map((val, i) => (
                  // eslint-disable-next-line
                  <TableItem
                    id={Math.floor(Math.random() * 10e8)}
                    isExist={this.isRelatedAnnouncesExist(tag, i)}
                    findRelatedAnnounce={this.findRelatedAnnounce(tag, i)}
                    handleShow={this.handleShow}
                    handleDelete={this.handleDelete}
                    tag={tag}
                    // eslint-disable-next-line
                    key={i}
                    slotId={i}
                  />
                ))}
                {showCode && (
                  <td>
                    <CopyToClipboard
                      text={paramName === 'video_video' ? `[relatedcontent-${tag.id}-${postId}-video]` : `[relatedcontent-${tag.id}-${postId}]`}
                    >
                      <button
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        <i className="fa fa-files-o" aria-hidden="true" />
                      </button>
                    </CopyToClipboard>
                    <input
                      style={{ padding: "0", height: "auto" }}
                      type="text"
                      value={paramName === 'video_video' ? `[relatedcontent-${tag.id}-${postId}-video]` : `[relatedcontent-${tag.id}-${postId}]`}
                      readOnly
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {showModal && (
          <Modal>
            <div
              onClick={e => this.handleHide(e)}
              className="modal in anim"
              tabIndex="-1"
              style={{ display: "block" }}
            >
              <a
                href=""
                className="modal-close"
                data-aria-hidden="true"
                data-dismiss="modal"
              >
                <img alt="Close" src="/assets/v2/icons/close.svg" />
              </a>
              <div
                className="modal-dialog"
                ref={node => {
                  this.modal = node;
                }}
              >
                <div className="modal-content">
                  <h2 className="header_h2">{`#${selectedTag.name}`}</h2>
                  <ul className="tabs tabs_type">
                    {tabs.map((keyName, index) => (
                      <li
                        // eslint-disable-next-line
                        key={index}
                        className={cn({
                          current_page:
                            getTAbIndexByModelName(keyName) === activeTAbIndex
                        })}
                      >
                        {items[keyName].items.length !== 0 && (
                          <a
                            href=""
                            onClick={() => this.handleClickTab(keyName)}
                          >
                            {getTitle(`${keyName}`)}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>

                  <div className="profile_page">
                    {tabs.map((keyName, index) => (
                      <RelatedAnnouncesItem
                        // eslint-disable-next-line
                        key={index}
                        items={items[keyName].items}
                        count={items[keyName].count}
                        index={index}
                        keyName={keyName}
                        handleSelectRelated={this.handleSelectRelated}
                        selectedSlot={selectedSlot}
                        selectedTag={selectedTag}
                        activeTAbIndex={activeTAbIndex}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" />
          </Modal>
        )}
      </div>
    );
  }
}
