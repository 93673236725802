import { Suspense, lazy } from "react";

const FlashMessages = lazy(() => import("./FlashMessages"));

const FlashMessagesWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <FlashMessages {...props} />
    </Suspense>
  );
};

export default FlashMessagesWrapper;
