import axios from "axios";

axios.defaults.headers = {
  "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
    ? document.querySelector('meta[name="csrf-token"]').getAttribute("content")
    : "",
  "X-Requested-With": "XMLHttpRequest"
};

axios.interceptors.response.use(
  // eslint-disable-next-line
  function(response) {
    return response;
  },
  // eslint-disable-next-line
  function(error) {
    return Promise.reject(error);
  }
);

axios.defaults.validateStatus = status => status >= 200 && status <= 500;

export default axios;
