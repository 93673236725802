import { PureComponent } from "react";
import cn from "classnames";
import shortid from "shortid";
import PropTypes from "prop-types";
import axios from "../../utils/axios";
import Modal from "../../components/Modal";

const initialState = {
  showModal: false,
  showContentAnim: false,
  selectedDates: [],
  errors: {}
};

export default class InterviewUnregisterModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showContentAnim: false,
      dates: JSON.parse(this.props.dates),
      selectedDates: [],
      errors: {}
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = e => {
    if (e.keyCode === 27) {
      this.handleHide();
    } else {
      return;
    }
    if (this.modal && this.modal.contains(e.target));
  };

  onSubmit = () => {
    const {
      state: { selectedDates },
      props: { url }
    } = this;

    axios
      .post(url, {
        dates: selectedDates
      })
      .then(({ data }) => {
        if (data && data.errors) {
          this.setState({
            errors: data.errors
          });
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      })
      .catch(err => {
        console.log("parsing error", err);
      });
  };

  onDateChange = ({ target: { value } }) => {
    const {
      state: { selectedDates }
    } = this;

    let newSelectedDates = [];
    if (selectedDates.includes(value)) {
      newSelectedDates = selectedDates.filter(date => date !== value);
    } else {
      newSelectedDates = [...selectedDates, value];
    }
    this.setState({ selectedDates: newSelectedDates });
  };

  handleHide = e => {
    if (e) {
      if (!this.questions.contains(e.target)) e.preventDefault();
      if (this.modal.contains(e.target)) return;
    }
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.setState({ ...initialState });
  };

  handleShow = e => {
    e.preventDefault();
    this.setState({
      showModal: true
    });
    this.timeoutId = setTimeout(() => {
      this.setState({ showContentAnim: true });
    }, 100);
  };

  renderDates = () => {
    const {
      state: { dates, selectedDates }
    } = this;

    const randId = shortid.generate();
    return (
      (dates.length > 0 && (
        <div className="form_primary-group med">
          <div className="input">
            <label htmlFor={randId} className="control-label">
              Отмена записи на
              <span className="form_primary-required">*</span>
            </label>
            <div className="collection_wrapper">
              {dates.map(date => (
                <span className="checkbox" key={date.id}>
                  <input
                    className="form_primary-checkbox"
                    id={date.id}
                    value={date.id}
                    type="checkbox"
                    checked={selectedDates.includes(String(date.id))}
                    onChange={this.onDateChange}
                  />
                  <label htmlFor={date.id}>{date.title}</label>
                </span>
              ))}
            </div>
          </div>
        </div>
      )) ||
      null
    );
  };

  render() {
    const {
      state: { showModal, showContentAnim },
      props: { caption, btnClass }
    } = this;

    return (
      <React.Fragment>
        <a
          style={{ cursor: "pointer" }}
          className={btnClass}
          onClick={this.handleShow}
        >
          <img alt="Checked" src="/assets/v2/icons/checked.svg" /> {caption}
        </a>

        {showModal && (
          <Modal>
            <div
              onClick={this.handleHide}
              className={cn("modal modal-iterview", {
                "in anim": showContentAnim
              })}
              tabIndex="-1"
              style={{ display: "block" }}
            >
              <a href="" className="modal-close">
                <img alt="Close" src="/assets/v2/icons/close.svg" />
              </a>
              <div
                className="modal-dialog"
                ref={node => {
                  this.modal = node;
                }}
              >
                <div className="modal-content">
                  <React.Fragment>
                    <h4 className="modal-title">Отмена регистрации</h4>
                    <p className="modal-description">
                      При отмене регистрации Ваша регистрационная анкета будет
                      удалена.
                    </p>
                    <div
                      className="questions simple_form form_primary"
                      ref={node => {
                        this.questions = node;
                      }}
                    >
                      {this.renderDates()}

                      <div className="btn btn-green" onClick={this.onSubmit}>
                        Отправить
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
            <div className="modal-backdrop in" />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

InterviewUnregisterModal.defaultProps = {
  caption: "Регистрация",
  dates: "[]",
  btnClass: "",
  url: ""
};

InterviewUnregisterModal.propTypes = {
  caption: PropTypes.string,
  dates: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  btnClass: PropTypes.string,
  url: PropTypes.string.isRequired
};
