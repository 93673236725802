import { PureComponent } from "react";
import Select from "react-select";
import axios from "../../utils/axios";

export default class TagsAutocomplete extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: []
    };
  }

  componentWillMount() {
    if (JSON.parse(this.props.init).length > 0) {
      const value = [];
      JSON.parse(this.props.init).map(d =>
        value.push({
          label: d.name ? d.name : d,
          value: d.name ? d.name : d,
          id: d.id ? d.id : null,
          name: d.name ? d.name : ""
        })
      );
      this.setState({
        value
      });
    }
  }

  getOptions = (searchTerm, callback) => {
    if (searchTerm.length < 2) {
      return callback(null, { options: [] });
    }
    const { props: { type } } = this;
    return axios(
      type === "posts"
        ? `/posts/tags?query=${searchTerm}`
        : `/tags?query=${searchTerm}`
    )
      .then(({data}) => {
        const cachedOptions = data.map(d => ({
          label: d.name ? d.name : d,
          value: d.name ? d.name : d,
          id: d.id ? d.id : null,
          name: d.name ? d.name : ""
        }));
        return { options: cachedOptions };
      })
      .catch(err => {
        console.log("parsing failed", err);
      });
  };

  stringifiedTags = value => value.map(item => item.value).join(", ");

  handleSelectChange = newValue => {
    const { props: { handleTagsChange } } = this;
    if (handleTagsChange) {
      handleTagsChange(newValue);
    }
    if (newValue.length > 0) {
      this.setState({ value: newValue });
    } else {
      this.setState({ value: [] });
    }
  };

  render() {
    const { state: { value }, props: { name } } = this;

    return (
      <div>
        <Select.Async
          multi={true}
          removeSelected={true}
          arrowRenderer={null}
          clearable={false}
          searchPromptText=""
          loadingPlaceholder="Загружаем"
          onChange={this.handleSelectChange}
          placeholder="Введите название тега"
          loadOptions={this.getOptions}
          value={value}
        />
        <input type="hidden" name={name} value={this.stringifiedTags(value)} />
      </div>
    );
  }
}
