import cn from "classnames";
import Overlay from "../Overlay";

export default function MobileMenu({
  headerMenuLinks,
  isUserLoggedIn,
  userBarLinks,
  toggleMobileMenu,
  isMobileMenuOpen
}) {
  return (
    <span>
      <div className="burger-mobile" onClick={toggleMobileMenu}>
        <span />
        <span />
        <span />
        <span />
      </div>
      <div className="mobile-menu" tabIndex="-1">
        <a href="https://kampus.team/?utm_source=theoryandpracticeru&utm_medium=referral&utm_campaign=header_forbusiness_button" className="btn-small header-btn">
          Для бизнеса
        </a>
        <ul className="mobile-header-menu">
          {JSON.parse(headerMenuLinks)
            .filter(l => l.name !== "Моя лента")
            .map((link, index) => (
              // eslint-disable-next-line
              <li key={index} className="item">
                <a
                  href={link.link}
                  className={cn({
                    current_category: link.is_active
                  })}
                >
                  <span>{link.name}</span>
                </a>
              </li>
            ))}
        </ul>

        {/* <a
            href="/addition"
            className="add_an_event add_an_event-mobile item"
            data-tracker-label="Adding Event Initated"
            rel="nofollow"
          >
            <img
              alt="Add"
              className="add-an-event-img loaded"
              src="/assets/v2/icons/add.svg"
            />
            <div className="add-an-event-title">Создать анонс</div>
          </a> */}

        {/* <a
            href="/promo"
            className="add_an_event add_an_event-mobile item"
            data-tracker-label="Promo Event Initated"
            rel="nofollow"
          >
            <img
              alt="Promo"
              className="add-an-event-img loaded"
              src="/assets/v2/icons/add.svg"
            />
            <div className="add-an-event-title">Продвинуть анонс</div>
          </a> */}

        {isUserLoggedIn && (
          <ul className="user_panel user_dropdown-body">
            {JSON.parse(userBarLinks).map((link, index) => (
              // eslint-disable-next-line
              <li key={index}>
                <a
                  href={link.link}
                  className={link.class}
                  rel={link.rel}
                  role={link.role}
                  data-method={link.method}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        )}
        {!isUserLoggedIn && (
          <a href="/users/sign_in" className="item show_login" rel="nofollow">
            Войти
          </a>
        )}
      </div>

      {isMobileMenuOpen && (
        <Overlay withOutOverflowed={false}>
          <div
            className="main_header-toggle_menu-overlay"
            onClick={toggleMobileMenu}
          />
        </Overlay>
      )}
    </span>
  );
}
