import { Suspense, lazy } from "react";

const PresentableEditors = lazy(() => import("./PresentableEditors"));

const PresentableEditorsWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <PresentableEditors {...props} />
    </Suspense>
  );
};

export default PresentableEditorsWrapper;
