import { Suspense, lazy } from "react";

const FbShareButton = lazy(() => import("./FbShareButton"));

const FbShareButtonWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="Загрузка">
      <FbShareButton {...props} />
    </Suspense>
  );
};

export default FbShareButtonWrapper;
