import { PureComponent } from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import axios from "../../utils/axios";
import {
  debounce,
  getTitle,
  getAddingTitle,
  getNewTitle,
  getPlaceholder
} from "../../helpers/AutocompleteHelper";
import FormItem from "./FormItem";
import Field from "./Field";
import UsersAutocomplete from "../UsersAutocomplete";

const DragHandle = SortableHandle(() => (
  <span className="autocomplete_field-handle">
    <img src="/assets/v2/icons/handle.svg" alt="Handle" height="17" width="9" />
  </span>
));

const SortableItem = SortableElement(
  ({
    field,
    index,
    fields,
    modelName,
    attributeName,
    deleteField,
    changeMainOrganizationId,
    mainOrganizationId
  }) => (
    <div key={index} className="autocomplete_field_wrapper">
      <input type="hidden" name={`${attributeName}[]`} value={field.id} />
      <Field
        field={field}
        hasMultipleFields={fields.length > 1}
        circle={modelName === "presenter"}
        modelName={modelName}
        changeMainOrganizationId={changeMainOrganizationId}
        mainOrganizationId={mainOrganizationId}
      />
      {fields.length > 1 && <DragHandle />}
      <div
        className={cn("autocomplete_field-delete", {
          right: fields.length === 1
        })}
        onClick={() => deleteField(field.id)}
      />
    </div>
  )
);

const SortableList = SortableContainer(
  ({
    fields,
    modelName,
    attributeName,
    deleteField,
    changeMainOrganizationId,
    mainOrganizationId
  }) => (
    <div>
      {fields.map((field, index) => (
        <SortableItem
          // eslint-disable-next-line
          key={index}
          index={index}
          field={field}
          fields={fields}
          modelName={modelName}
          attributeName={attributeName}
          deleteField={deleteField}
          changeMainOrganizationId={changeMainOrganizationId}
          mainOrganizationId={mainOrganizationId}
        />
      ))}
    </div>
  )
);

export default class Autocomplete extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cities: this.props.cities,
      fields: this.props.fields,
      autocompleteFields: [],
      searchTerm: "",
      addingNewField: false,
      isSearching: false,
      isAdding: false,
      activeNewFieldTab: 1,
      editorsIds: [],
      mainOrganizationId: parseInt(this.props.mainOrganizationId, 10),
      presentersType: this.props.presentersType,
      errors: {}
    };
  }

  componentWillMount() {
    this.handleSearchDebounced = debounce(() => {
      this.handleSearch.apply(this, [this.state.searchTerm]);
    }, 300);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  onChange = ({ target: { value } }) => {
    this.setState({ searchTerm: value });
    if (value.length > 2) {
      this.handleSearchDebounced();
    } else {
      this.setState({ autocompleteFields: [] });
    }
  };

  onSubmitEditor = id => {
    const {
      state: { editorsIds }
    } = this;
    this.setState({
      editorsIds: [...editorsIds, id]
    });
  };

  onDeleteEditor = id => {
    const {
      state: { editorsIds }
    } = this;
    this.setState({
      editorsIds: editorsIds.filter(item => item !== id)
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const {
      state: { fields },
      props: { onSortEnd }
    } = this;

    if (onSortEnd) {
      onSortEnd(arrayMove(fields, oldIndex, newIndex));
    }

    this.setState({
      fields: arrayMove(fields, oldIndex, newIndex)
    });
  };

  onClickFieldTab = activeNewFieldTab => {
    this.setState({ activeNewFieldTab });
  };

  // eslint-disable-next-line
  handleClickOutside = ({ target }) => {
    const {
      state: { searchTerm }
    } = this;
    const area = this.autocompleteWrapper;
    if (area.contains(target)) {
      return false;
    } else if (searchTerm && searchTerm.length > 2) {
      this.setState({ searchTerm: "" });
    }
  };

  createNewField = () => {
    this.setState({ isAdding: true });
    const {
      state: { editorsIds },
      props: { modelName, logoField, descriptionField, editorsField }
    } = this;
    const payload = {};
    if (this.newFieldName) {
      payload[modelName] = {
        // eslint-disable-next-line
        name: ReactDOM.findDOMNode(this.newFieldName.refs.field).value
      };
    }
    if (this.newFieldShortDescription) {
      // eslint-disable-next-line
      payload[modelName].short = ReactDOM.findDOMNode(
        this.newFieldShortDescription.refs.field
      ).value;
    }
    if (this.newFieldDescription) {
      // eslint-disable-next-line
      payload[modelName][descriptionField] = ReactDOM.findDOMNode(
        this.newFieldDescription.refs.field
      ).value;
    }
    if (editorsField && editorsIds.length > 0) {
      payload[modelName][editorsField] = editorsIds;
    }
    if (this.newFieldAddress) {
      // eslint-disable-next-line
      payload[modelName].address = ReactDOM.findDOMNode(
        this.newFieldAddress.refs.field
      ).value;
    }
    if (this.newFieldCity) {
      // eslint-disable-next-line
      payload[modelName].city_id = ReactDOM.findDOMNode(
        this.newFieldCity.refs.field
      ).value;
    }
    if (this.newFieldImageLogo) {
      payload[logoField] = [
        // eslint-disable-next-line
        { id: ReactDOM.findDOMNode(this.newFieldImageLogo.refs.field).value }
      ];
    } else {
      payload[logoField] = [{}];
    }
    if (this.newFieldPhone) {
      // eslint-disable-next-line
      payload[modelName].phone_number = ReactDOM.findDOMNode(
        this.newFieldPhone.refs.field
      ).value;
    }
    if (this.newFieldEmail) {
      // eslint-disable-next-line
      payload[modelName].email = ReactDOM.findDOMNode(
        this.newFieldEmail.refs.field
      ).value;
    }
    if (this.newFieldWebsite) {
      // eslint-disable-next-line
      payload[modelName].website = ReactDOM.findDOMNode(
        this.newFieldWebsite.refs.field
      ).value;
    }
    if (this.newFieldFacebook) {
      // eslint-disable-next-line
      payload[modelName].facebook = ReactDOM.findDOMNode(
        this.newFieldFacebook.refs.field
      ).value;
    }
    if (this.newFieldVkontakte) {
      // eslint-disable-next-line
      payload[modelName].vkontakte = ReactDOM.findDOMNode(
        this.newFieldVkontakte.refs.field
      ).value;
    }
    if (this.newFieldTelegram) {
      // eslint-disable-next-line
      payload[modelName].telegram = ReactDOM.findDOMNode(
        this.newFieldTelegram.refs.field
      ).value;
    }
    axios({
      method: "post",
      url: `/${modelName}s.js`,
      data: {
        [modelName]: payload[modelName],
        [logoField]: payload[logoField]
      }
    })
      .then(({ data }) => {
        if (!data.error) {
          this.addNewField(data);
        } else {
          this.setState({ isAdding: false, errors: data.errors });
        }
      })
      .catch(err => {
        console.log("parsing failed", err);
      });
  };

  hideNewFieldForm = () => {
    this.setState({
      addingNewField: false,
      searchTerm: "",
      errors: {}
    });
    this.clearNewFieldForm();
  };

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.setState({ searchTerm: "" });
    }
  };

  nothingFound = () => {
    const {
      state: { resultItems }
    } = this;
    return resultItems.seminars.length === 0;
  };

  hasField = (id, fields) => fields.some(el => el.id === id);

  addField = index => {
    const {
      state: { autocompleteFields, fields },
      props: { onAddField }
    } = this;
    const id = autocompleteFields[index].id;
    if (!this.hasField(id, fields)) {
      fields.push(autocompleteFields[index]);
      if (onAddField) {
        onAddField(id);
      }
      this.setState({ fields });
    }
    this.setState({
      autocompleteFields: [],
      searchTerm: ""
    });
  };

  deleteField = id => {
    const {
      state: { fields },
      props: { onDeleteField }
    } = this;
    const filtered = fields.filter(el => el.id !== id);
    if (onDeleteField) {
      onDeleteField(id);
    }
    this.setState({ fields: filtered });
  };

  changeMainOrganizationId = (e, id) => {
    e.preventDefault();
    const {
      state: { mainOrganizationId }
    } = this;
    if (mainOrganizationId === id) {
      this.setState({ mainOrganizationId: null });
    } else {
      this.setState({ mainOrganizationId: id });
    }
  };

  clearNewFieldForm = () => {
    this.setState({ isAdding: false });
  };

  showNewFieldForm = () => {
    this.setState({ addingNewField: true });
  };

  onChangePresentersType = presentersType => {
    this.setState({ presentersType });
  };

  addNewField = field => {
    const {
      state: { fields }
    } = this;
    this.setState({
      fields: [...fields, field],
      addingNewField: false,
      errors: {}
    });
    this.hideNewFieldForm();
  };

  handleSearch(value) {
    const {
      props: { modelName }
    } = this;
    this.setState({ isSearching: true });
    const cityField = document.querySelector('select[name$="[city_id]"]');
    const cityId = cityField && cityField.value;
    let url = `/${modelName}s/autocomplete_new?term=${value}`;
    if (modelName === "place" && cityId && cityId.length > 0) {
      url = `/${modelName}s/autocomplete_new?term=${value}&city_id=${cityId}`;
    }
    axios
      .get(url)
      .then(({ data }) => {
        this.setState({
          isSearching: false,
          autocompleteFields: data[`${modelName}s`]
        });
      })
      .catch(err => {
        console.log("parsing error", err);
      });
  }

  render() {
    const {
      state: {
        autocompleteFields,
        searchTerm,
        fields,
        cities,
        errors,
        isSearching,
        addingNewField,
        activeNewFieldTab,
        mainOrganizationId,
        presentersType
      },
      props: {
        isInputEmpty,
        isMultiple,
        addBlank,
        modelName,
        attributeName,
        logoField,
        custom
      }
    } = this;

    window.organizationIds = fields.map(item => item.id);

    return (
      <div>
        {modelName === "presenter" && !custom && (
          <div className="radio-buttons-row">
            <div
              className="collection_wrapper"
              style={{ margin: "0 0 23px 0" }}
            >
              <input
                name="presenters_type"
                value={presentersType}
                type="hidden"
              />
              <span
                className="radio"
                onClick={() => this.onChangePresentersType("lecturer")}
              >
                <input
                  className="radio_buttons"
                  id="presenter_type_lecturer"
                  type="radio"
                  checked={presentersType === "lecturer"}
                  value="lecturer"
                />
                <label
                  className="collection_radio_buttons"
                  htmlFor="presenter_type_lecturer"
                >
                  Преподаватели
                </label>
              </span>
              <span
                className="radio"
                onClick={() => this.onChangePresentersType("speaker")}
              >
                <input
                  className="radio_buttons"
                  id="presenter_type_speaker"
                  type="radio"
                  checked={presentersType === "speaker"}
                  value="speaker"
                />
                <label
                  className="collection_radio_buttons"
                  htmlFor="presenter_type_speaker"
                >
                  Спикеры
                </label>
              </span>
            </div>
          </div>
        )}

        <div
          className="autocomplete_input_wrapper"
          ref={node => {
            this.autocompleteWrapper = node;
          }}
        >
          {(isMultiple || fields.length === 0) && !addingNewField && (
            <input
              className={cn("autocomplete_input", {
                active: searchTerm.length > 2
              })}
              value={searchTerm}
              placeholder={getPlaceholder(modelName, presentersType)}
              onChange={this.onChange}
              onKeyDown={this.handleKeyDown}
              type="text"
            />
          )}

          {searchTerm.length > 2 && !addingNewField && (
            <div className="autocomplete_list_wrapper">
              {autocompleteFields.length > 0 &&
                autocompleteFields.map((field, index) => (
                  <Field
                    field={field}
                    circle={modelName === "presenter"}
                    // eslint-disable-next-line
                    key={index}
                    modelName={modelName}
                    index={index}
                    searchField={true}
                    addField={this.addField}
                  />
                ))}
              {!isSearching && (
                <div
                  className="autocomplete_field_wrapper"
                  onClick={() => this.showNewFieldForm()}
                >
                  <span className="autocomplete_list_wrapper-add">{`Добавить ${getAddingTitle(
                    modelName,
                    presentersType
                  )} \u00ab${searchTerm}\u00bb`}</span>
                </div>
              )}
            </div>
          )}

          {!isInputEmpty && fields.length > 0 && (
            <span className="autocomplete_title">
              {getTitle(modelName, presentersType)}
            </span>
          )}

          {addBlank && (
            <input type="hidden" name={`${attributeName}[]`} value="" />
          )}

          <SortableList
            fields={fields}
            modelName={modelName}
            attributeName={attributeName}
            onSortEnd={this.onSortEnd}
            deleteField={this.deleteField}
            changeMainOrganizationId={this.changeMainOrganizationId}
            mainOrganizationId={mainOrganizationId}
            useDragHandle={true}
          />

          {addingNewField && (
            <div className="autocomplete_form_wrapper">
              <span className="autocomplete_form_wrapper-title">
                {getNewTitle(modelName, presentersType)}
              </span>

              <ul className="tab-nav">
                <li
                  className={cn("tab-nav-li", {
                    active: activeNewFieldTab === 1
                  })}
                >
                  <a
                    className="font-size-small tab-nav-item"
                    onClick={() => this.onClickFieldTab(1)}
                  >
                    Профиль
                  </a>
                </li>
                <li
                  className={cn("tab-nav-li", {
                    active: activeNewFieldTab === 2
                  })}
                >
                  <a
                    className="font-size-small tab-nav-item"
                    onClick={() => this.onClickFieldTab(2)}
                  >
                    Контакты
                  </a>
                </li>
                <li
                  className={cn("tab-nav-li", {
                    active: activeNewFieldTab === 3
                  })}
                >
                  <a
                    className="font-size-small tab-nav-item"
                    onClick={() => this.onClickFieldTab(3)}
                  >
                    Администраторы
                  </a>
                </li>
              </ul>

              <div className="form_primary-section">
                <div
                  className={cn("tab-nav-pane", {
                    active: activeNewFieldTab === 1
                  })}
                >
                  <FormItem
                    fieldName="name"
                    modelName={modelName}
                    errors={errors}
                    searchTerm={searchTerm}
                    ref={node => {
                      this.newFieldName = node;
                    }}
                  />
                  {modelName !== "place" && (
                    <FormItem
                      fieldName="short"
                      modelName={modelName}
                      rows={2}
                      maxLength={150}
                      errors={errors}
                      ref={node => {
                        this.newFieldShortDescription = node;
                      }}
                    />
                  )}
                  <FormItem
                    fieldName="description"
                    modelName={modelName}
                    rows={3}
                    maxLength={500}
                    errors={errors}
                    ref={node => {
                      this.newFieldDescription = node;
                    }}
                  />
                  <FormItem
                    fieldName="new_image"
                    modelName={modelName}
                    logoField={logoField}
                    ref={node => {
                      this.newFieldImageLogo = node;
                    }}
                  />

                  {modelName === "place" && (
                    <FormItem
                      fieldName="address"
                      modelName={modelName}
                      errors={errors}
                      noLabel={true}
                      ref={node => {
                        this.newFieldAddress = node;
                      }}
                    />
                  )}
                  {modelName === "place" && (
                    <FormItem
                      fieldName="city_id"
                      modelName={modelName}
                      errors={errors}
                      noLabel={true}
                      cities={cities}
                      ref={node => {
                        this.newFieldCity = node;
                      }}
                    />
                  )}
                </div>

                <div
                  className={cn("tab-nav-pane", {
                    active: activeNewFieldTab === 2
                  })}
                >
                  <FormItem
                    fieldName="new_phone"
                    modelName={modelName}
                    ref={node => {
                      this.newFieldPhone = node;
                    }}
                  />
                  <FormItem
                    fieldName="new_email"
                    modelName={modelName}
                    ref={node => {
                      this.newFieldEmail = node;
                    }}
                  />
                  <FormItem
                    fieldName="new_website"
                    modelName={modelName}
                    ref={node => {
                      this.newFieldWebsite = node;
                    }}
                  />
                  <FormItem
                    fieldName="new_facebook"
                    modelName={modelName}
                    ref={node => {
                      this.newFieldFacebook = node;
                    }}
                  />
                  <FormItem
                    fieldName="new_vkontakte"
                    modelName={modelName}
                    ref={node => {
                      this.newFieldVkontakte = node;
                    }}
                  />
                  <FormItem
                    fieldName="new_telegram"
                    modelName={modelName}
                    ref={node => {
                      this.newFieldTelegram = node;
                    }}
                  />
                </div>

                <div
                  className={cn("tab-nav-pane", {
                    active: activeNewFieldTab === 3
                  })}
                >
                  <UsersAutocomplete
                    onSubmitEditor={this.onSubmitEditor}
                    onDeleteEditor={this.onDeleteEditor}
                  />
                </div>
              </div>

              <a
                className="autocomplete_form_wrapper-add"
                onClick={() => this.createNewField()}
              >
                Создать
              </a>
              <a
                className="autocomplete_form_wrapper-cancel"
                onClick={() => this.hideNewFieldForm()}
              >
                Отмена
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
