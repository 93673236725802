import { Suspense, lazy } from "react";

const DayFilter = lazy(() => import("./DayFilter"));

const DayFilterWrapper = ({ ...props }) => {
  return (
    <Suspense fallback="">
      <DayFilter {...props} />
    </Suspense>
  );
};

export default DayFilterWrapper;
