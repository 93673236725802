import { PureComponent } from "react";
import PropTypes from "prop-types";
import TagsAutocomplete from "../../components/TagsAutocomplete";
import RelatedAnnounces from "../../components/RelatedAnnounces";

export default class RelatedTags extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      parsedTags: []
    };
  }

  componentWillMount() {
    const {
      props: { tags }
    } = this;
    if (tags.length > 0) {
      this.setState({
        parsedTags: [...JSON.parse(tags)]
      });
    }
  }

  handleTagsChange = parsedTags => {
    this.setState({ parsedTags });
  };

  render() {
    const {
      state: { parsedTags },
      props: { tags, relatedAnnounces, relatedPosts, postId, paramName }
    } = this;

    return (
      <div>
        <div className="input">
          <TagsAutocomplete
            handleTagsChange={this.handleTagsChange}
            init={tags}
            name={`${paramName}[tag_list]`}
            type="posts"
          />
        </div>

        <RelatedAnnounces
          title="Блоки с рекомендациями"
          parsedTags={parsedTags}
          postId={postId}
          relatedAnnounces={relatedAnnounces}
          inputName={`${paramName}[related_announces_attributes]`}
          showCode={true}
          paramName={paramName}
        />

        <RelatedAnnounces
          title="Рекомендуемые посты"
          parsedTags={parsedTags}
          postId={postId}
          relatedAnnounces={relatedPosts}
          inputName={`${paramName}[related_posts_attributes]`}
          slotsCount={6}
          onlyPosts={true}
          paramName={paramName}
        />
      </div>
    );
  }
}

RelatedTags.defaultProps = {
  paramName: "media_post"
};

RelatedTags.propTypes = {
  paramName: PropTypes.string
};
